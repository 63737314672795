import type React from "react"
import { useState, useEffect } from "react"
import {
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  useTheme,
  Skeleton,
  useMediaQuery,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"
import { Search, Refresh } from "@mui/icons-material"
import AdminLayout from "./components/AdminLayout"
import api from "../../services/api"
import TransactionDetailsTable from "./components/TransactionDetailsTable"
import { formatUSD } from "../../utils/formatUSD"
import StatCard from "./components/StatCard"

interface TotalEntriesData {
  totalPaid: number
  totalPaidPix: number
  totalPaidUsdt: number
}

interface TransactionDetail {
  userId: number
  userName: string
  userEmail: string
  userPhone: string
  transactionId: number
  qrcode: string
  price: number
  status: string
}

interface TransactionDetailsResponse {
  data: TransactionDetail[]
  pagination: {
    total: number
    page: number
    limit: number
    pages: number
  }
}

const NUBANK_PURPLE = "#8A05BE"

const Dashboard: React.FC = () => {
  const [data, setData] = useState<TotalEntriesData | null>(null)
  const [transactionDetails, setTransactionDetails] = useState<TransactionDetailsResponse | null>(null)
  const [loading, setLoading] = useState(true)
  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().getFullYear(), 0, 1))
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [searchTerm, setSearchTerm] = useState("")
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const fetchData = async () => {
    setLoading(true)
    try {
      const [totalResponse, detailsResponse] = await Promise.all([
        api.get<TotalEntriesData>("/admin/statistics/entries/total", {
          params: {
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
          },
        }),
        api.get<TransactionDetailsResponse>("/admin/statistics/entries/details", {
          params: {
            page: 1,
            limit: 10,
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
            search: searchTerm,
          },
        }),
      ])
      setData(totalResponse.data)
      setTransactionDetails(detailsResponse.data)
    } catch (error) {
      console.error("Error fetching dashboard data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const chartData = data
    ? [
      { name: "Total Paid", value: (data.totalPaid / 100) },
      { name: "Total Paid PIX", value: (data.totalPaidPix / 100) },
      { name: "Total Paid USDT", value: (data.totalPaidUsdt / 100) },
    ]
    : []

  const renderStatCards = () => {
    if (loading) {
      return Array(3)
        .fill(0)
        .map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Skeleton
              variant="rectangular"
              height={140}
              sx={{
                borderRadius: "10px",
                bgcolor: "rgba(255, 255, 255, 0.1)",
              }}
            />
          </Grid>
        ))
    }

    return (
      <>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Paid"
            value={data ? formatUSD(data.totalPaid) : "Loading..."}
            color="#fff"
            backgroundColor={NUBANK_PURPLE}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Paid PIX"
            value={data ? formatUSD(data.totalPaidPix) : "Loading..."}
            color="#fff"
            backgroundColor="#1a1a1a"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Total Paid USDT"
            value={data ? formatUSD(data.totalPaidUsdt) : "Loading..."}
            color="#fff"
            backgroundColor="#1a1a1a"
          />
        </Grid>
      </>
    )
  }

  return (
    <AdminLayout title="Dashboard">
      <Box
        sx={{
          mb: 4,
          color: "#fff",
          px: { xs: 1, sm: 2, md: 3 },
          py: { xs: 2, sm: 3 },
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue: Date | null) => setStartDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: isMobile ? "small" : "medium",
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(255, 255, 255, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(255, 255, 255, 0.5)",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "rgba(255, 255, 255, 0.7)",
                      },
                      "& .MuiInputBase-input": {
                        color: "#fff",
                      },
                      "& .MuiIconButton-root": {
                        color: "#fff",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue: Date | null) => setEndDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: isMobile ? "small" : "medium",
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "rgba(255, 255, 255, 0.23)",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(255, 255, 255, 0.5)",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "rgba(255, 255, 255, 0.7)",
                      },
                      "& .MuiInputBase-input": {
                        color: "#fff",
                      },
                      "& .MuiIconButton-root": {
                        color: "#fff",
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size={isMobile ? "small" : "medium"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: "#fff" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.23)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.5)",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "rgba(255, 255, 255, 0.7)",
                },
                "& .MuiInputBase-input": {
                  color: "#fff",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={fetchData}
              startIcon={<Refresh />}
              size={isMobile ? "small" : "medium"}
              sx={{
                backgroundColor: NUBANK_PURPLE,
                "&:hover": {
                  backgroundColor: "#6B0495",
                },
              }}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} sx={{ px: { xs: 1, sm: 2, md: 3 } }}>
        {renderStatCards()}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: { xs: 2, sm: 3 },
              display: "flex",
              flexDirection: "column",
              height: { xs: 300, sm: 400 },
              background: "#121212",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
              Payment Distribution
            </Typography>
            {loading ? (
              <Skeleton
                variant="rectangular"
                height="100%"
                sx={{
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "8px",
                }}
              />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  margin={{
                    top: 20,
                    right: isMobile ? 10 : 30,
                    left: isMobile ? 10 : 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
                  <XAxis
                    dataKey="name"
                    stroke="#fff"
                    tick={{ fontSize: isMobile ? 10 : 12 }}
                    interval={isMobile ? 1 : 0}
                  />
                  <YAxis stroke="#fff" tick={{ fontSize: isMobile ? 10 : 12 }} />
                  <Tooltip
                    contentStyle={{
                      background: "#1E1E1E",
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      color: "#fff",
                      fontSize: isMobile ? 12 : 14,
                    }}
                  />
                  <Legend
                    wrapperStyle={{
                      color: "#fff",
                      fontSize: isMobile ? 10 : 12,
                    }}
                  />
                  <Bar dataKey="value" fill={NUBANK_PURPLE} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: { xs: 2, sm: 3 },
              display: "flex",
              flexDirection: "column",
              background: "#121212",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ color: "#fff" }}>
              Recent Transactions
            </Typography>
            <TransactionDetailsTable
              transactions={transactionDetails?.data ?? []}
              pagination={transactionDetails?.pagination}
              loading={loading}
              onPageChange={(newPage) => {
                console.log("Page changed to:", newPage)
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </AdminLayout>
  )
}

export default Dashboard

