import type React from "react"
import { useState, useCallback, useMemo } from "react"
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  Avatar,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import {
  Search,
  Refresh,
  Download,
  AccountBalance,
  TrendingUp,
  MonetizationOn,
  CalendarToday,
  AttachMoney,
  Category,
} from "@mui/icons-material"
import AdminLayout from "./components/AdminLayout"
import api from "../../services/api"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"
import { formatUSD } from "../../utils/formatUSD"

const NUBANK_PURPLE = "#8A05BE"
const NUBANK_PURPLE_DARK = "#6B0495" // Darker purple for active tab

interface AuditData {
  deposits: DepositData[]
  investments: InvestmentData[]
  commissions: CommissionData[]
}

interface DepositData {
  id: number
  amount: number
  status: string
  createdAt: string
}

interface InvestmentData {
  id: number
  amount: number
  type: string
  createdAt: string
}

interface CommissionData {
  id: number
  amount: number
  type: string
  createdAt: string
}

const UserAudit: React.FC = () => {
  const [userIdentifier, setUserIdentifier] = useState("")
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(30, "day"))
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs())
  const [auditData, setAuditData] = useState<AuditData | null>(null)
  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const fetchAuditData = useCallback(async () => {
    if (!userIdentifier.trim()) {
      setError("Please enter a user email or username")
      return
    }

    setLoading(true)
    setError(null)
    try {
      const params = new URLSearchParams()
      params.append("userIdentifier", userIdentifier)
      if (startDate) params.append("startDate", startDate.toISOString())
      if (endDate) params.append("endDate", endDate.toISOString())

      const response = await api.get<AuditData>(`/admin/statistics/users/audit?${params.toString()}`)
      setAuditData(response.data)
    } catch (error) {
      console.error("Error fetching user audit data:", error)
      setError("Failed to fetch audit data. Please try again.")
    } finally {
      setLoading(false)
    }
  }, [userIdentifier, startDate, endDate])

  const handleExport = () => {
    // Implement export functionality here
    console.log("Exporting data...")
  }

  const formatAmount = (amount: number | null | undefined): string => {
    if (amount === null || amount === undefined || isNaN(amount)) {
      return formatUSD(0)
    }
    return formatUSD(amount)
  }

  const renderAuditCards = (data: DepositData[] | InvestmentData[] | CommissionData[], type: string) => {
    if (data.length === 0) {
      return <Typography sx={{ color: "#fff", mt: 2 }}>No data available for this category.</Typography>
    }

    const getIcon = (itemType: string) => {
      switch (itemType.toLowerCase()) {
        case "deposit":
          return <AccountBalance />
        case "investment":
          return <TrendingUp />
        case "commission":
          return <MonetizationOn />
        default:
          return <Category />
      }
    }

    return (
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                bgcolor: "#2a2a2a",
                color: "#fff",
                height: "100%",
                transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: `0 4px 20px ${NUBANK_PURPLE}40`,
                },
              }}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Avatar sx={{ bgcolor: NUBANK_PURPLE, mr: 2 }}>{getIcon(type)}</Avatar>
                  <Typography variant="h6">
                    {type} #{item.id}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <AttachMoney sx={{ mr: 1 }} />
                  <Typography variant="body1">{formatAmount(item.amount)}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <CalendarToday sx={{ mr: 1 }} />
                  <Typography variant="body2">{dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>
                </Box>
                <Chip
                // @ts-ignore
                  label={item?.status || item?.type}
                  sx={{
                    bgcolor: NUBANK_PURPLE,
                    color: "#fff",
                    mt: 1,
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  const tabContent = useMemo(() => {
    if (!auditData) return null
    switch (activeTab) {
      case 0:
        return renderAuditCards(auditData.deposits, "Deposit")
      case 1:
        return renderAuditCards(auditData.investments, "Investment")
      case 2:
        return renderAuditCards(auditData.commissions, "Commission")
      default:
        return null
    }
  }, [auditData, activeTab])

  return (
    <AdminLayout title="User Audit">
      <Paper sx={{ p: 3, mb: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            fullWidth
            label="User Email or Username"
            value={userIdentifier}
            onChange={(e) => setUserIdentifier(e.target.value)}
            InputProps={{
              startAdornment: <Search sx={{ color: "rgba(255, 255, 255, 0.7)", mr: 1 }} />,
            }}
            sx={{
              input: { color: "#fff" },
              label: { color: "#fff" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              },
            }}
          />
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue: Dayjs | null) => setStartDate(newValue)}
              slotProps={{
                textField: {
                  sx: {
                    input: { color: "#fff" },
                    label: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                      "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                    },
                  },
                },
              }}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue: Dayjs | null) => setEndDate(newValue)}
              slotProps={{
                textField: {
                  sx: {
                    input: { color: "#fff" },
                    label: { color: "#fff" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                      "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                    },
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={fetchAuditData}
              disabled={loading}
              startIcon={<Refresh />}
              sx={{
                bgcolor: NUBANK_PURPLE,
                "&:hover": { bgcolor: NUBANK_PURPLE_DARK },
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Fetch Audit Data"}
            </Button>
            <Tooltip title="Export Data">
              <IconButton onClick={handleExport} sx={{ color: "#fff" }}>
                <Download />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3, bgcolor: "#2C1616", color: "#fff" }}>
          {error}
        </Alert>
      )}

      {auditData && (
        <Paper sx={{ p: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant={isMobile ? "fullWidth" : "standard"}
            sx={{
              mb: 2,
              "& .MuiTab-root": {
                color: "#fff",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              },
              "& .Mui-selected": {
                color: "#fff !important",
                backgroundColor: NUBANK_PURPLE_DARK,
                opacity: 1,
                fontWeight: "bold",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#fff",
                height: "3px",
              },
            }}
          >
            <Tab
              label="Deposits"
              icon={<AccountBalance />}
              iconPosition="start"
              sx={{
                borderRadius: "8px 8px 0 0",
                "&.Mui-selected": {
                  color: "#fff",
                },
              }}
            />
            <Tab
              label="Investments"
              icon={<TrendingUp />}
              iconPosition="start"
              sx={{
                borderRadius: "8px 8px 0 0",
                "&.Mui-selected": {
                  color: "#fff",
                },
              }}
            />
            <Tab
              label="Commissions"
              icon={<MonetizationOn />}
              iconPosition="start"
              sx={{
                borderRadius: "8px 8px 0 0",
                "&.Mui-selected": {
                  color: "#fff",
                },
              }}
            />
          </Tabs>
          {tabContent}
        </Paper>
      )}
    </AdminLayout>
  )
}

export default UserAudit

