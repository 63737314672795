import React from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

const CardContainer = styled.div`
  background-color: #1A1A1A;
  border-radius: 12px;
  padding: 20px;
  color: white;
  width: 100%;
  text-align: center;
  border: 1px solid #8A2BE2;
`;

const SkeletonBox = styled.div`
  background: linear-gradient(90deg, #1A1A1A 25%, #2A2A2A 50%, #1A1A1A 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  border-radius: 5px;
`;

const Title = styled.h1`
  font-size: 1rem;
  color: #bfbfbf;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const TotalValue = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #8A2BE2;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ReferralList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ReferralItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 8px;
  padding: 8px;
  background-color: #2A2A2A;
  border-radius: 5px;

  p {
    margin: 0;
    color: #bfbfbf;
  }

  b {
    font-weight: bold;
    color: #8A2BE2;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const SkeletonTitle = styled(SkeletonBox)`
  height: 20px;
  width: 150px;
  margin: 0 auto 10px;
`;

const SkeletonTotal = styled(SkeletonBox)`
  height: 30px;
  width: 100px;
  margin: 0 auto 20px;
`;

const SkeletonItem = styled(SkeletonBox)`
  height: 40px;
  margin-bottom: 8px;
`;

type ReferralData = {
  level: string;
  count: number;
};

type ReferralCardProps = {
  totalReferrals: number;
  referrals: ReferralData[];
  isLoading?: boolean;
};

const ReferralCard: React.FC<ReferralCardProps> = ({ totalReferrals, referrals, isLoading }) => {
  if (isLoading) {
    return (
      <CardContainer>
        <SkeletonTitle />
        <SkeletonTotal />
        {[1, 2, 3, 4, 5].map((i) => (
          <SkeletonItem key={i} />
        ))}
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <Title>Total Referrals</Title>
      <TotalValue>{totalReferrals.toLocaleString()}</TotalValue>
      <ReferralList>
        {referrals.map((referral, index) => (
          <ReferralItem key={index}>
            <p>{referral.level}</p>
            <b>{referral.count.toLocaleString()}</b>
          </ReferralItem>
        ))}
      </ReferralList>
    </CardContainer>
  );
};

export default ReferralCard;

