// src/pages/WithdrawalPage.tsx
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Tabs from "../../components/molecules/Tabs";
import Button from "../../components/atoms/Button";
import FormField from "../../components/molecules/FormField";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderLogged from "../../components/HeaderLogged";
import { NumericFormat } from "react-number-format";
import api from "../../services/api";
import Swal from "sweetalert2";
import WithdrawalTable from "./WithdrawalTable";
import { UserContext } from "../../context/UserContext";

// Styled Components
const Container = styled.div`
  background-color: #000;
  min-height: 100vh;
  color: white;
  margin-bottom: 20px;
`;

const BackIcon = styled(AiOutlineArrowLeft)`
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 10px;
  color: #b08cff;
`;

const TabSection = styled.div`
  margin-top: 1rem;
`;

const TransactionSection = styled.div`
  background: linear-gradient(258.96deg, rgba(171, 43, 254, 0.49) 0%, rgba(128, 11, 203, 0.22) 31.11%, rgba(128, 11, 203, 0.2) 74.42%);
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
`;

const ExceptionSection = styled.div`
  span:nth-child(1) {
    color: #00aeff;
  }
  span:nth-child(2) {
    color: #fff;
  }
  span:nth-child(3) {
    color: #ffffff85;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff85;
  margin: 14px 0px;
  border-radius: 5px;
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`;

const Select = styled.select`
  background: rgba(26, 26, 26, 0);
  color: white;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;

  option {
    background: #000;
  }
`;

const WithdrawalPage: React.FC = () => {
  const DOLLAR_PRICE = 1;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false); 
  const [formData, setFormData] = useState({
    pixKey: "",
    price: "",
    keyType: "pixRandom",
  });
  const { refreshUserInfo } = useContext(UserContext);

  const formatCPF = (value: string): string =>
    value.replace(/\D/g, "").replace(/(\d{3})(\d)/, "$1.$2").replace(/(\d{3})(\d)/, "$1.$2").replace(/(\d{3})(\d{2})$/, "$1-$2");

  const formatPhone = (value: string): string =>
    value.replace(/\D/g, "").replace(/^(\d{2})(\d)/, "+$1 ($2").replace(/(\d{2})(\d)/, "$1) $2").replace(/(\d{5})(\d)/, "$1-$2");

  const handleDynamicChange = (value: string) => {
    let formattedValue = value;

    switch (formData.keyType) {
      case "cpf":
        formattedValue = formatCPF(value);
        break;
      case "phone":
        formattedValue = formatPhone(value);
        break;
      case "pixRandom":
        formattedValue = value; 
        break;
      default:
        formattedValue = value;
    }

    setFormData({ ...formData, pixKey: formattedValue });
  };

  const reaisConverter = useMemo(() => {
    const centavosPrice = parseFloat(formData.price);
    return !isNaN(centavosPrice) ? parseFloat((centavosPrice * DOLLAR_PRICE).toFixed(2)) : 0;
  }, [formData.price]);

  const handleSubmit = async () => {
    if(formData.price === '') {
      return Swal.fire("Warning", "Insert a valid amount", "warning");
   }
    if(formData.pixKey === '') {
       return Swal.fire("Warning", "Insert a valid key", "warning");
    }
    if(parseInt(formData.price) < 10){
     return Swal.fire("Warning", "Minimum of $10", "warning");
    }
    try {
      await api.post("/user/withdraw", {
        pixKey: formData.pixKey,
        amount: reaisConverter * 100,
      });

      Swal.fire("Success!", "Your withdrawal request is being processed.", "success");

      await refreshUserInfo();

      setFormData({ pixKey: "", price: "", keyType: "pixRandom" });
      setRefreshData((prev) => !prev); 
    } catch (e) {
      Swal.fire("Warning", "Failed to process the withdrawal.", "warning");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <HeaderLogged key={refreshData.toString()} />
      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <BackIcon onClick={() => navigate("/home")} />
          <h3>Withdrawal</h3>
        </div>
        <TabSection>
          <Tabs
            options={[
              { name: "Withdrawal", action: () => setActiveTab(0) },
              { name: "Deposits", action: () => setActiveTab(1) },
            ]}
            styleType="underlined"
            activeTab={activeTab}
            onTabChange={(index) => {
              setActiveTab(index);
              if (index === 0) navigate("/withdrawal");
              if (index === 1) navigate("/deposit");
            }}
          />

        </TabSection>

        <TransactionSection>
          <NumericFormat
            value={formData.price}
            prefix={"$"}
            allowNegative={false}
            customInput={FormField}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(e) => setFormData({ ...formData, price: e.value })}
            placeholder="Amount"
            style={{ border: "2px solid #fff" }}
          />

          <Select
            value={formData.keyType}
            onChange={(e) => setFormData({ ...formData, keyType: e.target.value })}
          >
            <option value="pixRandom">Pix Random Key</option>
            <option value="email">Email</option>
            <option value="cpf">CPF</option>
            <option value="phone">Phone</option>
            <option value="wallet">USDT Wallet</option>
          </Select>

          <FormField
            placeholder={`Enter your ${formData.keyType.toUpperCase()}`}
            type="text"
            onChange={(e) => handleDynamicChange(e.target.value)}
            value={formData.pixKey}
            style={{ border: "2px solid #fff" }}
          />
          <p>- 8% fee</p>
          <p>- $1 = R$5.82</p>
        </TransactionSection>

        <WithdrawalTable key={refreshData.toString()} />

        <ActionButton variant="primary" isLoading={loading} onClick={handleSubmit}>
          Withdraw
        </ActionButton>
      </div>
    </Container>
  );
};

export default WithdrawalPage;
