import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { ApexOptions } from "apexcharts";
import { formatUSD } from "../../utils/formatUSD";

const ChartContainer = styled.div`
  background: linear-gradient(180deg, #0a0a0a 0%, #1a1a1a 100%);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

type DashboardChartProps = {
  data: { categories: string[]; series: number[] };
};

const DashboardChart: React.FC<DashboardChartProps> = ({ data }) => {
  const chartOptions: ApexOptions = {
    chart: {
      id: "dynamic-chart",
      toolbar: { show: false },
      animations: { enabled: true, speed: 800 },
      type: "area",
      background: "transparent",
    },
    xaxis: {
      categories: data.categories.map((date) => {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }),
      labels: {
        style: { colors: "#EAEAEA", fontSize: "12px", fontWeight: "bold" },
      },
      axisBorder: { color: "#444" },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => formatUSD(value),
        style: { colors: "#EAEAEA", fontSize: "12px" },
      },
    },
    colors: ["#800BCB"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#AA80D8"],
        opacityFrom: 0.8,
        opacityTo: 0.1,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
      colors: ["#AA80D8"],
    },
    markers: {
      size: 4,
      colors: ["#FFFFFF"],
      strokeColors: "#800BCB",
      strokeWidth: 2,
    },
    dataLabels: {
      enabled: true,
      formatter: (value: number) => formatUSD(value), // Formata o valor
      style: {
        // Removendo a cor branca, mantendo o estilo padrão
        fontSize: "12px",
        fontWeight: "bold",
      },
    },
    tooltip: {
      theme: "dark",
      style: { fontSize: "14px" },
      y: {
        formatter: (value: number) => formatUSD(value),
      },
    },
    grid: { borderColor: "#444" },
  };




  const chartSeries = [
    {
      name: "Total",
      data: data.series, // Totais normalizados
    },
  ];

  return (
    <ChartContainer>
      <div className="row text-center ">
        {/* <h4>Weekly Profits</h4> */}
      </div>
      {/* <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={350} /> */}
    </ChartContainer>
  );
};

export default DashboardChart;
