import type React from "react"
import { useState } from "react"
import { AppBar, Box, CssBaseline, IconButton, Toolbar, Typography, Container } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { useNavigate } from "react-router-dom"
import { AdminSidebar } from "./AdminSidebar"
import { ArrowBack } from "@mui/icons-material"

interface AdminLayoutProps {
  children: React.ReactNode
  title: string
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children, title }) => {
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        backgroundColor: "#121212",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#1a1a1a" }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <AdminSidebar open={mobileOpen} onClose={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          width: { xs: "100%", sm: `calc(100% - 240px)` },
          mt: { xs: "56px", sm: "64px" }, // Adjust for smaller AppBar on mobile
        }}
      >
        <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, mb: { xs: 2, sm: 4 }, px: { xs: 1, sm: 3 } }}>
          <Box display="flex" alignItems="center" gap={2} mb={3}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                color: "#fff",
                display: { xs: "none", sm: "inline-flex" },
              }}
              aria-label="Go back"
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h4" sx={{ color: "#fff", fontSize: { xs: "1.5rem", sm: "2rem", md: "2.125rem" } }}>
              {title}
            </Typography>
          </Box>
          {children}
        </Container>
      </Box>
    </Box>
  )
}

export default AdminLayout

