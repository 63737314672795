import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineDollar } from "react-icons/ai";
import Tabs from "../components/molecules/Tabs";
import Button from "../components/atoms/Button";
import FormField from "../components/molecules/FormField";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderLogged from "../components/HeaderLogged";
import { MdOutlinePix } from "react-icons/md";
import { FaBity, FaCopy, FaUniversity } from "react-icons/fa";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import api from "../services/api";
import QRCode from "react-qr-code";
import { UserContext } from "../context/UserContext";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { CircularProgress } from "@mui/material";
import BottomMenu from "../components/BottomMenu";
import { DOLLAR_PRICE } from "../constants/dollar";

const Container = styled.div`
  background-color: #000;
  min-height: 100vh;
  color: white;
  margin-bottom: 80px;
  padding-bottom: 20px;
`;

const BackIcon = styled(AiOutlineArrowLeft)`
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 10px;
  color: #b08cff;
`;

const TabSection = styled.div`
  margin-top: 1rem;
`;

const PixButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: linear-gradient(180deg, rgba(34, 4, 54, 0.69) 0%, #220436 100%);
  backdrop-filter: blur(4px);
  box-shadow: 0px 0px 7px 1px #ffffff40;
  color: #fff;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  margin-top: 0.5rem;
`;

const TransactionSection = styled.div`
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
`;

const ExceptionSection = styled.div`
  span:nth-child(1) {
    color: #00aeff;
  }
  span:nth-child(2) {
    color: #fff;
  }
  span:nth-child(3) {
    color: #ffffff85;
  }
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 80px;
`;

const Stepper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  color: #ffffff;
  max-width: 400px;
  margin: auto;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .num {
    z-index: 2;
  }
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 24px;
    top: 75px;
    width: 2px;
    height: calc(100% - 180px);
    z-index: 0;
    background-color: #d9d9d9;
  }

  div:first-child {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #190228 0%, #4d1272 100%);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-right: 15px;
    padding: 20px;
  }

  div:last-child {
    h5 {
      color: #800bcb;
    }
  }
`;

const ToReceive = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    color: #00ff95;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff85;
  margin: 14px 0px;
  border-radius: 5px;
`;

const QrCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const QrCodeAdjusts = styled.div`
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
`;

const CopyCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.hover};
    }
  }
`;

const ApprovedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    color: greenyellow;
    font-size: 100px;
  }
`;

const UsdtDepositPage: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("PENDING");
  const { decodedToken, setBalance } = useContext(UserContext);

  const reaisConverter = useMemo(() => {
    const usdtAmount = parseFloat(amount);
    if (!isNaN(usdtAmount)) {
      return parseFloat((usdtAmount * DOLLAR_PRICE).toFixed(2));
    }
    return 0;
  }, [amount]);

  const tabOptions = [
    { name: "Withdrawal", action: () => navigate("/withdrawal") },
    { name: "Deposits", action: () => navigate("/deposit") },
  ];

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    tabOptions[index].action();
  };

  const handleSubmit = async () => {
    if (parseFloat(amount) < 12) {
      Swal.fire("Warning", "Minimum of $12 to deposit with crypto.", "warning");
      return;
    }

    try {
      setLoading(true);
      const res = await api.post("/payment/generate/nowpayments", {
        cryptoCurrency: "usdtbsc",
        amount: parseFloat(amount),
      });

      if (res.data && res.data.qrcode && res.data.transactionId) {
        setQrCode(res.data.qrcode);
        startChecking(res.data.transactionId);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error generating payment:", error);
      Swal.fire("Error", "Failed to generate payment. Please try again later.", "error");
    } finally {
      setLoading(false);
    }
  };

  const startChecking = (transactionId: string) => {
    const intervalId = setInterval(async () => {
      await fetchTransactionStatus(transactionId);
    }, 30000);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 60000 * 10);
  };

  const fetchTransactionStatus = async (transactionId: string) => {
    try {
      const response = await api.get(`/payment/transactionStatus/nowpayments/${transactionId}`);
      if (response.data && response.data.payment_status) {
        setTransactionStatus(response.data.payment_status);

        if (response.data.payment_status === "finished") {
          await updateUserBalance();
        }
      }
    } catch (error) {
      console.error("Error fetching transaction status:", error);
    }
  };

  const updateUserBalance = async () => {
    try {
      const res = await api.get("/user/info");
      if (res.data && res.data.balance && res.data.balance.amount) {
        setBalance(res.data.balance.amount);
      }
    } catch (error) {
      console.error("Error updating user balance:", error);
    }
  };

  return (
    <Container className="container-fluid pb-4">
      <HeaderLogged />
      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <BackIcon onClick={() => navigate("/home")} />
          <h3>Back</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <TabSection>
              <Tabs
                options={tabOptions}
                styleType="underlined"
                activeTab={activeTab}
                onTabChange={handleTabChange}
              />
            </TabSection>
          </div>
        </div>
        <div className="row mt-3 justify-content-center">
          <div className="col-4">
            <PixButton variant="primary" onClick={() => navigate("/deposit")}>
              <MdOutlinePix />
              PIX
            </PixButton>
          </div>
          <div className="col-4">
            <PixButton variant="primary">
              <AiOutlineDollar />
              USDT
            </PixButton>
          </div>
          <div className="col-4">
            <PixButton variant="primary">
              <FaBity />
              BFXC
            </PixButton>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <TransactionSection>
              <NumericFormat
                value={amount}
                prefix={"$"}
                allowNegative={false}
                customInput={FormField}
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(e) => setAmount(e.value)}
                placeholder="Amount in USDT"
                style={{ border: "2px solid #fff" }}
              />
              <ExceptionSection>
                <span>* </span>
                <span>Network: </span>
                <span>Binance - BEP20</span>
              </ExceptionSection>
              <ExceptionSection>
                <span>* </span>
                <span>Limit of:</span>
                <span> $12 - $40.000</span>
              </ExceptionSection>
              <div></div>
              <ToReceive>
                {/* <FaUniversity /> BRL Conversion:
                <span>R$ {reaisConverter.toLocaleString()}</span> */}
              </ToReceive>
              <Line></Line>
            </TransactionSection>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            {!qrCode ? (
              <Stepper>
                <Step>
                  <div>1</div>
                  <div>
                    <h5>Enter the USDT amount:</h5>
                    <p>
                      Specify the amount of USDT you want to deposit.
                    </p>
                  </div>
                </Step>
                <Step>
                  <div className="num">2</div>
                  <div>
                    <h5>Generate QR code:</h5>
                    <p>
                      Click on "Confirm" to generate the QR code for your USDT deposit.
                    </p>
                  </div>
                </Step>
                <Step>
                  <div>3</div>
                  <div>
                    <h5>Complete the transaction:</h5>
                    <p>
                      Use the QR code or wallet address to send USDT from your wallet. The amount will be credited to your account once confirmed.
                    </p>
                  </div>
                </Step>
              </Stepper>
            ) : transactionStatus !== "finished" ? (
              <QrCodeContainer>
                <QrCodeAdjusts>
                  <QRCode value={qrCode} strokeWidth={10} />
                </QrCodeAdjusts>
                <CopyCode>
                  <FormField type="text" readOnly value={qrCode} />
                  <button
                    onClick={() => navigator.clipboard.writeText(qrCode)}
                  >
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      Copy <FaCopy />
                    </p>
                  </button>
                </CopyCode>
                <CircularProgress />
              </QrCodeContainer>
            ) : (
              <ApprovedContainer>
                <IoMdCheckmarkCircleOutline />
                <h1>Success!</h1>
                <p>Thank you for trusting our service!</p>
              </ApprovedContainer>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            {
              qrCode ? <></> : <>
                <ActionButton
                  variant="primary"
                  isLoading={loading}
                  onClick={handleSubmit}
                >
                  Confirm
                </ActionButton>
              </>
            }
          </div>
        </div>
      </div>
      <BottomMenu />
    </Container>
  );
};

export default UsdtDepositPage;

