import { jwtDecode } from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../services/api";
import { formatUSD } from "../utils/formatUSD";

interface IDecodedToken {
  email: string;
  id: number;
  username: string;
  iat: number;
  exp: number;
  name: string;
  balance: number;
  isAdmin?: boolean;
}

interface IUserInfo {
  email: string;
  id: number;
  isAdmin: boolean | null;
  name: string;
  phone: string;
  username: string;
}

type AuthContextData = {
  decodedToken: IDecodedToken;
  setDecodedToken: React.Dispatch<React.SetStateAction<IDecodedToken>>;
  formattedBalance: string;
  changeToken: (token: string) => void;
  setBalance: React.Dispatch<React.SetStateAction<number | undefined>>;
  refreshUserInfo: () => Promise<void>;
  signOut(): void;
  userInfo: IUserInfo;
};

export const UserContext = createContext({} as AuthContextData);

const initToken = {
  id: 0,
  username: "",
  exp: 0,
  iat: 0,
  email: "",
  name: "",
  balance: 0,
};

const authChannel = new BroadcastChannel("auth");
const messageReceiver = new BroadcastChannel("auth");

export function UserProvider() {
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);

  const [decodedToken, setDecodedToken] = useState<IDecodedToken>(
    localStorage.getItem("accessToken")
      ? jwtDecode(localStorage.getItem("accessToken")!)
      : initToken
  );

  const [balance, setBalance] = useState<number>();
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    email: "",
    id: 0,
    isAdmin: null,
    name: "",
    phone: "",
    username: "",
  });

  const navigate = useNavigate();

  const refreshUserInfo = async () => {
    setIsBalanceLoading(true);
    try {
      const res = await api.get("/user/info");
      setUserInfo(res.data);
      setBalance(res.data.balance.amount);
    } catch (err) {
      console.error("Error fetching user info:", err);
    } finally {
      setIsBalanceLoading(false);
    }
  };

  const checkTokenValidity = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const tokenExpiry = decodedToken?.exp; // Expiration time from JWT

    if (tokenExpiry && tokenExpiry < currentTime) {
      Swal.fire({
        title: "Session Ended",
        text: "Your session has expired. Please log in again to continue.",
        icon: "info",
        confirmButtonText: "Log In",
      }).then(() => {
        signOut(); // Logs the user out
      });
    }
  };

  useEffect(() => {
    refreshUserInfo();

    messageReceiver.onmessage = (message) => {
      switch (message.data) {
        case "signOut":
          localStorage.removeItem("accessToken");
          setDecodedToken(initToken);
          navigate("/login");
          break;
        default:
          break;
      }
    };

    authChannel.onmessage = (message) => {
      if (message.data === "updateToken") {
        const newToken = localStorage.getItem("accessToken");
        if (newToken) {
          setDecodedToken(jwtDecode(newToken));
          refreshUserInfo();
        }
      }
    };

    // Set interval to check token validity every minute
    const interval = setInterval(checkTokenValidity, 60000);
    checkTokenValidity();

    return () => {
      try {
        messageReceiver.close();
        authChannel.close();
      } catch (err) {
        console.error("Failed to close BroadcastChannel:", err);
      }
      clearInterval(interval);
    };
  }, [decodedToken, navigate]);

  function signOut() {
    try {
      if (authChannel && !authChannel.close) {
        authChannel.postMessage("signOut");
      }
    } catch (err) {
      console.error("Failed to send message through BroadcastChannel:", err);
    } finally {
      localStorage.removeItem("accessToken");
      setDecodedToken(initToken);
      navigate("/login");
    }
  }

  const changeToken = (token: string) => {
    if (!token || token === "undefined") {
      return;
    }
    localStorage.setItem("accessToken", token);
    setDecodedToken(jwtDecode(token));
  };

  useEffect(() => {
    // Periodic check of token validity
    const tokenCheckInterval = setInterval(() => {
      checkTokenValidity();
    }, 10000); // Check every 10 seconds

    return () => clearInterval(tokenCheckInterval);
  }, [decodedToken]);

  return (
    <UserContext.Provider
      value={{
        decodedToken,
        setDecodedToken,
        changeToken,
        formattedBalance: balance ? formatUSD(balance) : formatUSD(0),
        setBalance,
        refreshUserInfo,
        signOut,
        userInfo,
      }}
    >
      <Outlet />
    </UserContext.Provider>
  );
}