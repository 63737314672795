import type React from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from "@mui/material"
import { formatUSD } from "../../../utils/formatUSD"

interface TransactionDetail {
  userId: number
  userName: string
  userEmail: string
  userPhone: string
  transactionId: number
  qrcode: string
  price: number
  status: string
}

interface Pagination {
  total: number
  page: number
  limit: number
  pages: number
}

interface TransactionDetailsTableProps {
  transactions: TransactionDetail[]
  pagination: Pagination | undefined
  loading: boolean
  onPageChange: (newPage: number) => void
}

const TransactionDetailsTable: React.FC<TransactionDetailsTableProps> = ({
  transactions,
  pagination,
  loading,
  onPageChange,
}) => {
  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage + 1)
  }

  if (loading) {
    return <CircularProgress sx={{ color: "#8A05BE" }} />
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>User ID</TableCell>
              <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                User Name
              </TableCell>
              <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>Email</TableCell>
              <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>Phone</TableCell>
              <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                Transaction ID
              </TableCell>
              <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>Price</TableCell>
              <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow
                key={transaction.transactionId}
                hover
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(138, 5, 190, 0.1) !important",
                  },
                }}
              >
                <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                  {transaction.userId}
                </TableCell>
                <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                  {transaction.userName}
                </TableCell>
                <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                  {transaction.userEmail}
                </TableCell>
                <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                  {transaction.userPhone}
                </TableCell>
                <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                  {transaction.transactionId}
                </TableCell>
                <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                  {formatUSD(transaction.price)}
                </TableCell>
                <TableCell sx={{ color: "#fff", borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}>
                  {transaction.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          component="div"
          count={pagination.total}
          page={pagination.page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={[pagination.limit]}
          sx={{
            color: "#fff",
            ".MuiTablePagination-select": {
              color: "#fff",
            },
            ".MuiTablePagination-selectIcon": {
              color: "#fff",
            },
            ".MuiTablePagination-displayedRows": {
              color: "#fff",
            },
            ".MuiTablePagination-actions": {
              color: "#fff",
            },
          }}
        />
      )}
    </>
  )
}

export default TransactionDetailsTable

