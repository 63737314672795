// api.ts
import axios from "axios";
import { logout } from "./authService";

export const API_URL = 'https://bull-back.onrender.com/';
// export const API_URL = 'http://localhost:3001/';

const api = axios.create({
  baseURL: `${API_URL}`,
});

// Interceptor para adicionar o token de acesso
api.interceptors.request.use(
  (config) => {
    // config.headers['apikey'] = process.env.REACT_APP_API_KEY;
    const token = localStorage.getItem("accessToken");
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para atualizar o token de acesso usando o refreshToken
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // const refreshToken = localStorage.getItem("refreshToken");
        // if(!refreshToken) {
        //   return api(originalRequest);
        // }
        // const response = await axios.get(
        //   `${API_URL}/auth/token/refresh?refreshToken=${refreshToken}`
        // );
        // const { accessToken, refreshToken: newRefreshToken } = response.data;
        const accessToken = localStorage.getItem("accessToken");
        // localStorage.setItem("refreshToken", newRefreshToken);
        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        logout(); // Remover tokens e deslogar se o refresh falhar
        window.location.href = "/login"; // Redirecionar para a página de login
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
