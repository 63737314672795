import styled from "styled-components";

export const PageContainer = styled.div`
  background-color: #000;
  min-height: 100vh;
  padding-bottom: 60px;
  color: white;
`;

export const TradersList = styled.div`
  margin-top: 2rem;
  margin-bottom: 10rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const FilterContainer = styled.div`
  button {
    font-size: 1rem;
    color: #fff;
    width: 100%;
    background: #400565;
    border: none;
    font-weight: 400;
    box-shadow: 0px 0px 9px #cccccc59;
    padding: 10px 10px;
    @media (max-width: 768px){
      font-size: 0.7rem;
    }
    &:hover {
      background: #6720a6;
      box-shadow: 0px 0px 12px #6720a6;
    }
  }
`;
