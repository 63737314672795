import type React from "react"
import { useState } from "react"
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import ReactApexChart from "react-apexcharts"
import AdminLayout from "./components/AdminLayout"
import api from "../../services/api"
import { formatUSD } from "../../utils/formatUSD"

const NUBANK_PURPLE = "#8A05BE"

interface User {
  id: number
  username: string
  email: string
  childrenLevel1Id?: number
}

interface NetworkData {
  id: number
  childrenLevel1: User[]
  childrenLevel2: User[]
  childrenLevel3: User[]
  childrenLevel4: User[]
  childrenLevel5: User[]
  totalProductivity: number
}

const UserNetwork: React.FC = () => {
  const [userIdentifier, setUserIdentifier] = useState("")
  const [networkData, setNetworkData] = useState<NetworkData | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const fetchNetworkData = async () => {
    if (!userIdentifier.trim()) {
      setError("Please enter a user email or username")
      return
    }

    setLoading(true)
    setError(null)
    try {
      const response = await api.get<NetworkData>(`/admin/statistics/users/network?userIdentifier=${userIdentifier}`)
      setNetworkData(response.data)
    } catch (error) {
      console.error("Error fetching user network:", error)
      setError("Failed to fetch user network. Please try again.")
    } finally {
      setLoading(false)
    }
  }

  const getDownlineChartOptions = () => ({
    chart: {
      type: "bar",
      height: 350,
      dropShadow: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
        barHeight: "80%",
        isFunnel: true,
      },
    },
    colors: ["#B57BED", "#CA6CD8", "#D863B1", "#E55A89", "#F44F5E"],
    dataLabels: {
      enabled: true,
      formatter: (val: number) => val.toString(),
      style: {
        colors: ["#fff"],
      },
      dropShadow: {
        enabled: true,
      },
    },
    title: {
      text: "Childrens per level",
      align: "center",
      style: {
        color: "#fff",
      },
    },
    xaxis: {
      categories: ["Level 1", "Level 2", "Level 3", "Level 4", "Level 5"],
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    theme: {
      mode: "dark",
    },
    legend: {
      show: false,
    },
  })

  const getChartData = () => {
    if (!networkData) return []

    return [
      {
        name: "Members",
        data: [
          networkData.childrenLevel1.length,
          networkData.childrenLevel2.length,
          networkData.childrenLevel3.length,
          networkData.childrenLevel4.length,
          networkData.childrenLevel5.length,
        ],
      },
    ]
  }

  return (
    <AdminLayout title="User Network">
      <Paper sx={{ p: 3, mb: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
        <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", gap: 2 }}>
          <TextField
            fullWidth
            label="User Email or Username"
            value={userIdentifier}
            onChange={(e) => setUserIdentifier(e.target.value)}
            sx={{
              input: { color: "#fff" },
              label: { color: "#fff" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
              },
            }}
          />
          <Button
            variant="contained"
            onClick={fetchNetworkData}
            disabled={loading}
            sx={{
              bgcolor: NUBANK_PURPLE,
              "&:hover": { bgcolor: "#6B0495" },
              minWidth: isMobile ? "100%" : "auto",
            }}
          >
            {loading ? <CircularProgress size={24} color="primary" /> : "search"}
          </Button>
        </Box>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3, bgcolor: "#2C1616", color: "#fff" }}>
          {error}
        </Alert>
      )}

      {networkData && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Paper sx={{ p: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
            <Typography variant="h6" sx={{ color: "#fff", mb: 2 }}>
              Productivity
            </Typography>
            <Typography variant="h4" sx={{ color: "#fff" }}>
              {formatUSD(networkData.totalProductivity)}
            </Typography>
          </Paper>

          <Paper sx={{ p: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
            <Box sx={{ width: "100%", height: "400px" }}>
              <ReactApexChart
                // @ts-ignore
                options={getDownlineChartOptions()}
                series={getChartData()}
                type="bar"
                height="100%"
                width="100%"
              />
            </Box>
          </Paper>
        </Box>
      )}
    </AdminLayout>
  )
}

export default UserNetwork

