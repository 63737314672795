import type React from "react"
import { useState, useEffect, useCallback } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import ReactApexChart from "react-apexcharts"
import AdminLayout from "./components/AdminLayout"
import { formatUSD } from "../../utils/formatUSD"
import api from "../../services/api"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"

const NUBANK_PURPLE = "#8A05BE"

interface Transaction {
  id: number
  userId: number | null
  traderId: number | null
  type: TransactionType
  amount: number
  createdAt: string
  description: string
}

type TransactionType =
  | "COMMISSION"
  | "DIRECT_COMMISSION"
  | "RESIDUAL_COMMISSION"
  | "PROFIT"
  | "TRANSFER"
  | "WITHDRAW"
  | "TRANSFER_PROFIT"
  | "BUFFER_TRANSFER"
  | "CONTRACT_DONE"
  | "PROFIT_TRADE"
  | "TRADER_RESIDUAL_COMMISSION"

const typeColors: { [key in TransactionType]: string } = {
  COMMISSION: "#F44336",
  DIRECT_COMMISSION: "#E91E63",
  RESIDUAL_COMMISSION: "#9C27B0",
  PROFIT: "#4CAF50",
  TRANSFER: "#2196F3",
  WITHDRAW: "#FFC107",
  TRANSFER_PROFIT: "#00BCD4",
  BUFFER_TRANSFER: "#FF9800",
  CONTRACT_DONE: "#795548",
  PROFIT_TRADE: "#8BC34A",
  TRADER_RESIDUAL_COMMISSION: "#FF5722",
}

interface ChartDataPoint {
  x: number
  y: number
}

interface ChartSeries {
  name: string
  data: ChartDataPoint[]
}

type TotalAmounts = {
  COMMISSION: number
  PROFIT: number
  WITHDRAW: number
  TRANSFER: number
}

const TransactionLog: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(7, "day"))
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs())
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [chartData, setChartData] = useState<ChartSeries[]>([])
  const [totalAmounts, setTotalAmounts] = useState<TotalAmounts>({
    COMMISSION: 0,
    PROFIT: 0,
    WITHDRAW: 0,
    TRANSFER: 0,
  })

  const fetchTransactions = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const params = new URLSearchParams()
      if (startDate) params.append("startDate", startDate.toISOString())
      if (endDate) params.append("endDate", endDate.toISOString())

      const response = await api.get<{ data: Transaction[] }>(`/admin/statistics/transactions/log?${params.toString()}`)
      setTransactions(response.data.data)
      updateChartData(response.data.data)
    } catch (error) {
      console.error("Error fetching transactions:", error)
      setError("Failed to fetch transactions. Please try again.")
    } finally {
      setLoading(false)
    }
  }, [startDate, endDate])

  const updateChartData = (newTransactions: Transaction[]) => {
    const transactionsByType: Record<keyof TotalAmounts, ChartDataPoint[]> = {
      COMMISSION: [],
      PROFIT: [],
      WITHDRAW: [],
      TRANSFER: [],
    }

    const totals: TotalAmounts = {
      COMMISSION: 0,
      PROFIT: 0,
      WITHDRAW: 0,
      TRANSFER: 0,
    }

    newTransactions.forEach((transaction) => {
      const timestamp = new Date(transaction.createdAt).getTime()
      let category: keyof TotalAmounts = "TRANSFER" // default category

      if (transaction.type.includes("COMMISSION") || transaction.type.includes("RESIDUAL")) {
        category = "COMMISSION"
      } else if (transaction.type.includes("PROFIT")) {
        category = "PROFIT"
      } else if (transaction.type === "WITHDRAW") {
        category = "WITHDRAW"
      } else if (transaction.type.includes("TRANSFER")) {
        category = "TRANSFER"
      }

      const existingPoint = transactionsByType[category].find((point) => point.x === timestamp)
      if (existingPoint) {
        existingPoint.y += transaction.amount / 100
      } else {
        transactionsByType[category].push({
          x: timestamp,
          y: transaction.amount / 100,
        })
      }

      totals[category] += transaction.amount / 100
    })

    // Sort data points by timestamp for each category
    Object.keys(transactionsByType).forEach((category) => {
      transactionsByType[category as keyof TotalAmounts].sort((a, b) => a.x - b.x)
    })

    setChartData(
      Object.entries(transactionsByType).map(([name, data]) => ({
        name,
        data,
      })),
    )

    setTotalAmounts(totals)
  }

  useEffect(() => {
    fetchTransactions()
    const interval = setInterval(fetchTransactions, 60000) // Fetch every minute
    return () => clearInterval(interval)
  }, [fetchTransactions])

  const formatUSDChart = (value: number): string => `$${value.toFixed(0)}`

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "area",
      height: 350,
      background: "transparent",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: [typeColors.COMMISSION, typeColors.PROFIT, typeColors.WITHDRAW, typeColors.TRANSFER],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.1,
      },
    },
    title: {
      text: "Transaction Types Over Time",
      align: "left",
      style: {
        color: "#fff",
        fontSize: "18px",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
        },
        formatter: (value) => `$${value.toFixed(0)}`,
      },
    },
    legend: {
      labels: {
        colors: "#fff",
      },
    },
    grid: {
      borderColor: "#555",
    },
    theme: {
      mode: "dark",
    },
    tooltip: {
      theme: "dark",
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        formatter: (value) => formatUSDChart(value),
      },
    },
  }

  return (
    <AdminLayout title="Transaction Log">
      <Paper sx={{ p: 3, mb: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
        <Box sx={{ mb: 3, display: "flex", flexWrap: "wrap", gap: 2, alignItems: "center" }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue: Dayjs | null) => setStartDate(newValue)}
            slotProps={{
              textField: {
                sx: {
                  input: { color: "#fff" },
                  label: { color: "#fff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                    "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                  },
                },
              },
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue: Dayjs | null) => setEndDate(newValue)}
            slotProps={{
              textField: {
                sx: {
                  input: { color: "#fff" },
                  label: { color: "#fff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                    "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                  },
                },
              },
            }}
          />
          <Button
            variant="contained"
            onClick={fetchTransactions}
            sx={{
              bgcolor: NUBANK_PURPLE,
              "&:hover": { bgcolor: "#6B0495" },
            }}
          >
            Filter
          </Button>
        </Box>


        <Box sx={{ height: 400, mb: 3 }}>
          <ReactApexChart options={chartOptions} series={chartData} type="area" height={350} />
        </Box>

        {loading && <CircularProgress />}
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <TableContainer component={Paper} sx={{ bgcolor: "#2a2a2a", borderRadius: "10px", overflow: "hidden" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#1a1a1a" }}>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>ID</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>User ID</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Trader ID</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Type</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Amount</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Created At</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow key={transaction.id} sx={{ "&:hover": { bgcolor: "rgba(138, 5, 190, 0.1)" } }}>
                  <TableCell sx={{ color: "#fff" }}>{transaction.id}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{transaction.userId}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{transaction.traderId}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          bgcolor: typeColors[transaction.type],
                        }}
                      />
                      <Typography
                        sx={{
                          color: typeColors[transaction.type],
                          fontWeight: "bold",
                        }}
                      >
                        {transaction.type}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>{formatUSD(transaction.amount)}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{new Date(transaction.createdAt).toLocaleString()}</TableCell>
                  <TableCell sx={{ color: "#fff" }}>{transaction.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </AdminLayout>
  )
}

export default TransactionLog

