import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Divider,
} from "@mui/material"
import { Home, People, CreditCard, Description, BarChart, Settings } from "@mui/icons-material"

const menuItems = [
  { icon: Home, label: "Dashboard", path: "/adm/dashboard" },
  { icon: People, label: "User Network", path: "/adm/user-network" },
  { icon: CreditCard, label: "Withdraw Requests", path: "/adm/withdraw-requests" },
  { icon: Description, label: "Transaction Log", path: "/adm/transaction-log" },
  { icon: BarChart, label: "User Audit", path: "/adm/user-audit" },
  { icon: Settings, label: "Settings", path: "/adm/settings" },
]

interface AdminSidebarProps {
  open: boolean;
  onClose: () => void;
}

export function AdminSidebar({ open, onClose }: AdminSidebarProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const drawer = (
    <>
      <List>
        <ListItem>
          <ListItemButton onClick={() => navigate("/adm")}>
            <ListItemIcon>
              <Home sx={{ color: "#ffffff" }} />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="h6">Admin Panel</Typography>} />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ backgroundColor: "#ffffff3b" }} />
        {menuItems.map((item) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton
              selected={location.pathname === item.path}
              onClick={() => {
                navigate(item.path)
                onClose()
              }}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#ffffff1a",
                },
                "&:hover": {
                  backgroundColor: "#ffffff0d",
                },
              }}
            >
              <ListItemIcon>
                <item.icon sx={{ color: "#ffffff" }} />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  )

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          backgroundColor: "#1a1a1a",
          color: "#ffffff",
        },
      }}
    >
      {drawer}
    </Drawer>
  )
}
