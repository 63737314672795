import React, { useState } from "react";
import styled from "styled-components";
import { formatUSD } from "../utils/formatUSD";
import { FaChevronDown } from "react-icons/fa";

const CardContainer = styled.div`
  font-size: 0.9rem;
  padding: 20px;
  border-radius: 10px;
  font-weight: 500;
  background-color: #1A1A1A;
  border: 1px solid #8A2BE2;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2A2A2A;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    font-size: 1.8rem;
    color: #8A2BE2;
  }

  p {
    margin: 0;
    color: #FFFFFF;
    font-weight: 600;
  }
`;

const CardValue = styled.div`
  font-weight: bold;
  color: #FFFFFF;
  font-size: 1.2rem;
`;

const CollapseContent = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 200px;
  padding: 15px;
  background-color: #2A2A2A;
  border: 1px solid #8A2BE2;
`;

const ExpandIcon = styled.div<{ isExpanded: boolean }>`
  margin-left: 10px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${props => props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  color: #8A2BE2;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 1px solid #8A2BE2;
`;

const TableRow = styled.tr`
  background-color: #2A2A2A;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333333;
  }
`;

const TableCell = styled.td`
  padding: 12px 10px;
  color: #FFFFFF;
  font-size: 0.9rem;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

type StatementCardProps = {
  title: string;
  value: number;
  icon: React.ReactNode;
  isLoading?: boolean;
  collapseContent?: React.ReactNode;
};

const StatementCard: React.FC<StatementCardProps> = ({
  title,
  value,
  icon,
  isLoading = false,
  collapseContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCollapse = () => {
    if (collapseContent) setIsExpanded((prev) => !prev);
  };

  return (
    <CardContainer>
      <CardHeader onClick={toggleCollapse}>
        <CardTitle>
          {icon}
          <p>{title}</p>
        </CardTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardValue>{isLoading ? "Loading..." : formatUSD(value)}</CardValue>
          {collapseContent && (
            <ExpandIcon isExpanded={isExpanded}>
              <FaChevronDown />
            </ExpandIcon>
          )}
        </div>
      </CardHeader>
      {isExpanded && collapseContent && (
        <CollapseContent>
          {collapseContent}
        </CollapseContent>
      )}
    </CardContainer>
  );
};

export default StatementCard;

