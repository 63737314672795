
import { addDays, eachDayOfInterval, endOfMonth, format, getDay, parseISO, startOfMonth, startOfWeek } from "date-fns";
export const prepareChartData = (
  rawData: { date: string; total: number }[],
  startDate: Date,
  endDate: Date
) => {
  if (!rawData || rawData.length === 0) {
    console.warn("prepareChartData recebeu dados vazios.");
    return { categories: [], series: [] };
  }

  const allDates = eachDayOfInterval({ start: startDate, end: endDate }).map((date) =>
    format(date, "yyyy-MM-dd")
  );

  const dataMap = rawData.reduce((acc, { date, total }) => {
    const formattedDate = format(parseISO(date), "yyyy-MM-dd");
    acc[formattedDate] = (acc[formattedDate] || 0) + total; 
    return acc;
  }, {} as Record<string, number>);

  const categories = allDates;
  const series = allDates.map((date) => dataMap[date] || 0); 

  return { categories, series };
};
export const normalizeChartData = (
  rawData: { date: string; total: number }[],
  startDate: Date,
  endDate: Date
) => {
  const allDates = eachDayOfInterval({ start: startDate, end: endDate }).map((date) =>
    format(date, "yyyy-MM-dd")
  );

  const dataMap = rawData.reduce((acc, { date, total }) => {
    acc[date] = total;
    return acc;
  }, {} as Record<string, number>);

  const normalizedData = allDates.map((date) => ({
    date,
    total: dataMap[date] || 0,
  }));

  return normalizedData;
};

export const formatDate = (dateString: string): string => {
  return format(new Date(dateString), "dd/MM/yyyy");
};


export const getSundaysInMonth = (date: Date) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);
  const allDays = eachDayOfInterval({ start, end });
  return allDays.filter((day) => getDay(day) === 0); 
};

export const getWeeklyRange = (date: Date) => {
  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 0 }); // Sempre o domingo atual ou mais próximo
  const next7Days = Array.from({ length: 7 }, (_, i) => addDays(startOfWeekDate, i)); // Próximos 7 dias

  return {
    startOfWeek: startOfWeekDate,
    endOfWeek: addDays(startOfWeekDate, 6),
    daysInRange: next7Days.map((day) => format(day, "yyyy-MM-dd")), 
  };
};