import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { UserContext } from '../context/UserContext';
import { formatProfit, formatUSD } from '../utils/formatUSD';
import api from '../services/api';
import HeaderLogged from '../components/HeaderLogged';
import BottomMenu from '../components/BottomMenu';
import { FaSpinner, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageContainer = styled.div`
  background-color: #000000;
  min-height: 100vh;
  padding-bottom: 60px;
  color: #ffffff;
`;

const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledCard = styled(motion.div)`
  background: #1e1e1e;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 600px;
`;

const StyledTh = styled.th`
  background: #2e2e2e;
  color: #ffffff;
  text-align: left;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-bottom: 2px solid #3e3e3e;

  &:first-child {
    border-top-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
  }
`;

const StyledTd = styled.td`
  padding: 16px;
  border-bottom: 1px solid #3e3e3e;
  font-size: 14px;
  color: #ffffff;
`;

const StyledTr = styled.tr`
  &:last-child td {
    border-bottom: none;
  }

  &:hover {
    background-color: #2e2e2e;
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  font-size: 24px;
  margin: 20px auto;
  display: block;
  color: #8a05be;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const NoDataMessage = styled.p`
  text-align: center;
  font-size: 16px;
  color: #a0a0a0;
  margin: 40px 0;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledSelect = styled.select`
  background-color: #2e2e2e;
  color: #ffffff;
  border: 1px solid #3e3e3e;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;

  &:hover, &:focus {
    border-color: #8a05be;
    box-shadow: 0 0 0 0.2rem rgba(138, 5, 190, 0.25);
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

const PaginationButton = styled.button`
  background-color: #2e2e2e;
  color: #ffffff;
  border: 1px solid #8a05be;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #8a05be;
    color: #ffffff;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PaginationInfo = styled.span`
  color: #a0a0a0;
  font-size: 14px;
`;

const CardTitle = styled.h3`
  color: #8a05be;
  font-size: 18px;
  margin-bottom: 16px;
`;

const AmountPositive = styled.span`
  color: #28a745;
`;

const AmountNegative = styled.span`
  color: #ffffff;
`;

interface Withdraw {
  amount: number;
  pixKey: string;
  createdAt: string;
  status: string;
}

interface CommissionLevel {
  totalAmount: number;
  count: number;
  details: CommissionDetail[];
}

interface CommissionDetail {
  amount: number;
  createdAt: string;
  user: {
    username: string;
  };
}

interface ResidualCommission {
  type: string;
  amount: number;
  createdAt: string;
  description: string;
}

const StatementPage: React.FC = () => {
  const { decodedToken } = useContext(UserContext);
  const [pagination, setPagination] = useState({ currentPage: 1, pageSize: 10, totalCount: 0, totalPages: 1 });
  const [loading, setLoading] = useState(true);
  const [transactionType, setTransactionType] = useState<'RESIDUAL_COMMISSION' | 'WITHDRAW' | 'COMMISSION'>('RESIDUAL_COMMISSION');
  const [withdraws, setWithdraws] = useState<Withdraw[]>([]);
  const [commissions, setCommissions] = useState<Record<number, CommissionLevel>>({});
  const [residualCommissions, setResidualCommissions] = useState<ResidualCommission[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setWithdraws([]);
    setCommissions({});
    setResidualCommissions([]);

    try {
      const response = await api.post(`/traders/${decodedToken.id}/statement`, null, {
        params: {
          page: pagination.currentPage,
          pageSize: pagination.pageSize,
          transactionType,
        },
      });
      const data = response.data.data;
      setPagination(response.data.pagination);

      if (transactionType === 'WITHDRAW') {
        setWithdraws(data.approvedWithdraws || []);
      } else if (transactionType === 'COMMISSION') {
        setCommissions(data.commissions || {});
      } else if (transactionType === 'RESIDUAL_COMMISSION') {
        setResidualCommissions(data.residualCommissions || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [decodedToken.id, pagination.currentPage, pagination.pageSize, transactionType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTransactionTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTransactionType(event.target.value as 'RESIDUAL_COMMISSION' | 'WITHDRAW' | 'COMMISSION');
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPagination((prev) => ({ ...prev, pageSize: +event.target.value, currentPage: 1 }));
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/Sao_Paulo',
    });
  };

  return (
    <PageContainer>
      <HeaderLogged nameScreen="Account Statement" />
      <Container>
        <FilterContainer>
          <StyledSelect value={transactionType} onChange={handleTransactionTypeChange}>
            <option value="RESIDUAL_COMMISSION">Residual Commissions</option>
            <option value="WITHDRAW">Withdrawals</option>
            <option value="DIRECT_COMMISSION">Direct Commissions</option>
          </StyledSelect>
          <StyledSelect value={pagination.pageSize} onChange={handlePageSizeChange}>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </StyledSelect>
        </FilterContainer>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {transactionType === 'WITHDRAW' && withdraws.length > 0 && (
              <StyledCard>
                <CardTitle>Withdrawals</CardTitle>
                <TableWrapper>
                  <StyledTable>
                    <thead>
                      <tr>
                        <StyledTh>Amount</StyledTh>
                        <StyledTh>Date</StyledTh>
                        <StyledTh>Status</StyledTh>
                        <StyledTh>PIX Key</StyledTh>
                      </tr>
                    </thead>
                    <tbody>
                      {withdraws.map((withdraw, index) => (
                        <StyledTr key={index}>
                          <StyledTd>
                            {withdraw.status === 'paid' ? (
                              <AmountPositive>{formatUSD(withdraw.amount)}</AmountPositive>
                            ) : (
                              <AmountNegative>{formatUSD(withdraw.amount)}</AmountNegative>
                            )}
                          </StyledTd>
                          <StyledTd>{formatDate(withdraw.createdAt)}</StyledTd>
                          <StyledTd>{withdraw.status}</StyledTd>
                          <StyledTd>{withdraw.pixKey || 'N/A'}</StyledTd>
                        </StyledTr>
                      ))}
                    </tbody>
                  </StyledTable>
                </TableWrapper>
              </StyledCard>
            )}

{transactionType === 'COMMISSION' && Object.keys(commissions).length > 0 && (
  Object.entries(commissions).map(([level, data]) => {
    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedDetails = data.details.slice(startIndex, startIndex + pagination.pageSize);

    return (
      <StyledCard key={level}>
        <CardTitle>Commissions - Level {level}</CardTitle>
        <TableWrapper>
          <StyledTable>
            <thead>
              <tr>
                <StyledTh>Amount</StyledTh>
                <StyledTh>Date</StyledTh>
                <StyledTh>Generated By</StyledTh>
              </tr>
            </thead>
            <tbody>
              {paginatedDetails.map((detail, index) => (
                <StyledTr key={index}>
                  <StyledTd>
                    <AmountPositive>{formatUSD(detail.amount)}</AmountPositive>
                  </StyledTd>
                  <StyledTd>{formatDate(detail.createdAt)}</StyledTd>
                  <StyledTd>{detail.user.username}</StyledTd>
                </StyledTr>
              ))}
            </tbody>
          </StyledTable>
        </TableWrapper>

        {/* PAGINAÇÃO */}
        <PaginationContainer>
          <PaginationButton
            onClick={() => setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))}
            disabled={pagination.currentPage === 1}
          >
            <FaChevronLeft />
          </PaginationButton>
          <PaginationInfo>
            Page {pagination.currentPage} of {Math.ceil(data.details.length / pagination.pageSize)}
          </PaginationInfo>
          <PaginationButton
            onClick={() => setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }))}
            disabled={pagination.currentPage >= Math.ceil(data.details.length / pagination.pageSize)}
          >
            <FaChevronRight />
          </PaginationButton>
        </PaginationContainer>
      </StyledCard>
    );
  })
)}



            {transactionType === 'RESIDUAL_COMMISSION' && residualCommissions.length > 0 && (
              <StyledCard>
                <CardTitle>Residual Commissions</CardTitle>
                <TableWrapper>
                  <StyledTable>
                    <thead>
                      <tr>
                        <StyledTh>Type</StyledTh>
                        <StyledTh>Amount</StyledTh>
                        <StyledTh>Date</StyledTh>
                        <StyledTh>Description</StyledTh>
                      </tr>
                    </thead>
                    <tbody>
                      {residualCommissions.map((residual, index) => (
                        <StyledTr key={index}>
                          <StyledTd>Residual</StyledTd>
                          <StyledTd><AmountPositive>${residual.amount}</AmountPositive></StyledTd>
                          <StyledTd>{formatDate(residual.createdAt)}</StyledTd>
                          <StyledTd>{residual.description}</StyledTd>
                        </StyledTr>
                      ))}
                    </tbody>
                  </StyledTable>
                </TableWrapper>
              </StyledCard>
            )}

            {withdraws.length === 0 && Object.keys(commissions).length === 0 && residualCommissions.length === 0 && (
              <NoDataMessage>No transactions found for the selected criteria.</NoDataMessage>
            )}

            <PaginationContainer>
              <PaginationButton
                onClick={() => setPagination((prev) => ({ ...prev, currentPage: prev.currentPage - 1 }))}
                disabled={pagination.currentPage === 1}
              >
                <FaChevronLeft />
              </PaginationButton>
              <PaginationInfo>
                Page {pagination.currentPage} of {pagination.totalPages}
              </PaginationInfo>
              <PaginationButton
                onClick={() => setPagination((prev) => ({ ...prev, currentPage: prev.currentPage + 1 }))}
                disabled={pagination.currentPage === pagination.totalPages}
              >
                <FaChevronRight />
              </PaginationButton>
            </PaginationContainer>
          </>
        )}
      </Container>
      <BottomMenu />
    </PageContainer>
  );
};

export default StatementPage;
