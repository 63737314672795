import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import Tabs from "../../components/molecules/Tabs";
import BottomMenu from "../../components/BottomMenu";
import InfluencerCard from "../../components/InfluencerCard";
import HeaderLogged from "../../components/HeaderLogged";
import { UserContext } from "../../context/UserContext";
import api from "../../services/api";
import Skeleton from "@mui/material/Skeleton";
import { FilterContainer, TradersList } from "./HomePage.styles";
import DashboardHomeSection from "../Dashboard/DashboardHomeSection";

type TraderType = {
  id: number;
  name: string;
  country: string;
  market: string;
  term: number;
  estimatedProfit: string;
  minimumInvestment: number;
  maximumInvestment: number;
  avatar: string;
  bio: string;
  previousProfit: string;
  operationTerm: string;
  tradeType: string;
  createdAt: string;
  updatedAt: string;
};

const gradient = `linear-gradient(
  258.96deg,
  rgba(171, 43, 254, 0.49) 0%,
  rgba(128, 11, 203, 0.22) 31.11%,
  rgba(128, 11, 203, 0.2) 74.42%
)`;

const HomePage: React.FC = () => {
  const { t }: { t: any } = useTranslation(); 
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [traders, setTraders] = useState<TraderType[]>([]);
  const [filter, setFilter] = useState<string>("all");
  const { decodedToken, setBalance } = useContext(UserContext);

  useEffect(() => {
    const fetchTraders = async () => {
      try {
        const res = await api.get("/traders");
        setTraders(res.data);
      } catch (error) {
        console.error("Something went wrong. Try again", error);
      }
    };
    fetchTraders();
  }, []);

  const tabOptions = [
    { name: t("tabs.traders") as string, action: () => navigate("/home") },
    { name: t("tabs.my_investments") as string, action: () => navigate("/my-deposits") },
  ];

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    tabOptions[index].action();
  };

  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };

  const filteredTraders = traders.filter((trader) => {
    switch (filter) {
      case "beginners":
        return trader.term <= 6;
      case "highReturn":
        return parseFloat(trader.estimatedProfit) > 15;
      case "stableProfits":
        return parseFloat(trader.estimatedProfit) <= 15;
      default:
        return true;
    }
  });

  return (
    <>
      <HeaderLogged nameScreen={t("header.trading_history") as string} />
      <div className="container mt-3">
        <div className="row mt-4">
          <div className="col-12">
            <Tabs options={tabOptions} styleType="underlined" activeTab={activeTab} onTabChange={handleTabChange} />
          </div>
        </div>
        <FilterContainer>
          <div className="d-flex justify-content-center gap-3 mt-4">
            {["all", "beginners", "highReturn", "stableProfits"].map((filterOption) => (
              <button
                key={filterOption}
                className={`btn ${filter === filterOption ? "btn-primary" : "btn-outline-primary"}`}
                onClick={() => handleFilterChange(filterOption)}
              >
                {t(`filters.${filterOption}`) as string}
              </button>
            ))}
          </div>
        </FilterContainer>
        <TradersList>
          {filteredTraders.length
            ? filteredTraders.map((trader) => (
              <InfluencerCard
                key={trader.id}
                id={trader.id}
                term={trader.term}
                name={trader.name}
                subscribers={0}
                previousProfit={trader.previousProfit}
                operationTerm={trader.operationTerm}
                estimatedProfit={trader.estimatedProfit}
                profileImageSrc={trader.avatar}
                maximumInvestment={trader.maximumInvestment}
                minimumInvestment={trader.minimumInvestment}
                country={trader.country}
                market={trader.market}
                bio={trader.bio}
              />
            ))
            : Array(5)
              .fill("")
              .map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width="100%"
                  height={190}
                  sx={{ background: gradient, my: "10px" }}
                />
              ))}
        </TradersList>
      </div>
      <BottomMenu />
    </>
  );
};

export default HomePage;
