import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import HeaderLogged from "../components/HeaderLogged";
import Tabs from "../components/molecules/Tabs";
import InvestmentCard from "../components/InvestmentCard";
import TraderOperationsList from "../components/TraderOperationsList";
import BottomMenu from "../components/BottomMenu";
import api from "../services/api";
import { UserContext } from "../context/UserContext";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PageContainer = styled.div`
  background-color: #000;
  min-height: 100vh;
  padding-bottom: 60px;
  color: white;
`;

const DepositsList = styled.div`
  margin-top: 2rem;
`;

type DepositType = {
  id: number;
  amount: number;
  startDate: string;

  endDate: string;
  profit: number;
  profitProcessed: true;
  trader: {
    id: number;
    name: string;
      term: any;
    avatar: string;
    bio: string;
    previousProfit: string;
    estimatedProfit: string;
    operationTerm: string;
    minimumInvestment: number;
    maximumInvestment: number;
  };
  traderOperations: {
    profit: number;
    createdAt: string;
  }[];
  accumulatedProfit: number;
  dailyProfit: number;
};

const calculateDailyProfit = (traderOperations: { profit: number; createdAt: string }[]) => {
  const today = new Date().toISOString().split("T")[0]; // Formato YYYY-MM-DD
  return traderOperations
    .filter((operation) => operation.createdAt.split("T")[0] === today)
    .reduce((sum, operation) => sum + operation.profit, 0); // Soma dos profits
};

const MyDeposits: React.FC = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [deposits, setDeposits] = useState<DepositType[]>([]);
  const [selectedDeposit, setSelectedDeposit] = useState<DepositType | null>(null);
  const { decodedToken } = useContext(UserContext);
  const navigate = useNavigate();

  const tabOptions = [
    { name: "Traders", action: () => navigate("/home") },
    { name: "My Investments", action: () => navigate("/my-deposits") },
  ];

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    tabOptions[index].action();
  };

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        if (decodedToken?.id) {
          const res = await api.get(`/traders/history/${decodedToken.id}`);
          setDeposits(res.data);
        }
      } catch (error) {
        console.error("Failed to fetch deposits:", error);
      }
    };
    fetchDeposits();
  }, [decodedToken]);

  return (
    <PageContainer>
      <HeaderLogged nameScreen="My Investments" />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            {!selectedDeposit ? (
              <Tabs
                options={tabOptions}
                styleType="underlined"
                activeTab={activeTab}
                onTabChange={handleTabChange}
              />
            ) : null}
          </div>
        </div>
        {selectedDeposit ? (
          <>
            <FaArrowLeft onClick={() => navigate(0)} color="#fff" /> Back
            <TraderOperationsList operations={selectedDeposit.traderOperations} />
          </>
        ) : (
          <DepositsList>
            {deposits
              ?.filter((deposit) => !deposit.profitProcessed) // Filtra os depósitos onde profitProcessed é false
              .map((deposit) => (
                <InvestmentCard
                  key={deposit.id}
                  name={deposit.trader.name}
                  bio={deposit.trader.bio}
                  profileImageSrc={deposit.trader.avatar}
                  previousProfit={deposit.trader.previousProfit}
                  estimatedProfit={deposit.trader.estimatedProfit}
                  operationTerm={deposit.trader.operationTerm}
                  minimumInvestment={deposit.trader.minimumInvestment}
                  maximumInvestment={deposit.trader.maximumInvestment}
                  amount={deposit.amount}
                  startDate={deposit.startDate}
                  endDate={deposit.endDate}
                  profit={deposit.profit}
                  traderId={deposit.trader.id}
                  dailyProfit={deposit.dailyProfit}
                  accumulatedProfit={deposit.accumulatedProfit}
                  term={deposit.trader.term}
                  onClick={() => setSelectedDeposit(deposit)}
                />
              ))}
          </DepositsList>
        )}
        <BottomMenu />
      </div>
    </PageContainer>
  );
};

export default MyDeposits;


