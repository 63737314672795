import React, { useState, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { formatProfit } from "../utils/formatUSD";
import { HiTrendingUp, HiTrendingDown } from "react-icons/hi";

interface TraderOperationsListProps {
  operations: {
    profit: number;
    createdAt: string;
  }[];
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
`;

const HistoryContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #000000;
  color: #ffffff;
`;

const TotalProfitCard = styled.div<{ $isPositive: boolean }>`
  background: linear-gradient(145deg, #400565, #2d0347);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 30px;
  color: #ffffff;
  box-shadow: 0 8px 32px rgba(64, 5, 101, 0.3);
  text-align: center;
  position: relative;
  overflow: hidden;
  animation: ${fadeInUp} 0.5s ease-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.$isPositive ?
    'linear-gradient(145deg, rgba(255, 255, 255, 0.1), transparent)' :
    'linear-gradient(145deg, rgba(255, 77, 77, 0.1), transparent)'};
    z-index: 0;
  }
`;

const TotalProfitInner = styled.div`
  position: relative;
  z-index: 1;
`;

const TotalProfitTitle = styled.h2`
  font-size: 1.25rem;
  color: #ffffff;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const TotalProfitAmount = styled.div<{ $isPositive: boolean }>`
  font-size: 2.5rem;
  font-weight: 700;
  color: ${props => props.$isPositive ? '#97f979' : '#ff4d4d'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const TransactionCard = styled.div`
  background: linear-gradient(145deg, #400565, #2d0347);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  box-shadow: 0 4px 16px rgba(64, 5, 101, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: ${fadeInRight} 0.5s ease-out;
  animation-fill-mode: both;

  &:hover {
    transform: translateX(-5px);
    box-shadow: 4px 4px 20px rgba(64, 5, 101, 0.3);
  }
`;

const TransactionDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const TransactionTitle = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
`;

const TransactionSubtitle = styled.div`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
`;

const Amount = styled.div<{ $isPositive: boolean }>`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.$isPositive ? '#97f979' : '#ff4d4d'};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconWrapper = styled.div<{ $isPositive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.$isPositive ?
    'rgba(255, 255, 255, 0.1)' :
    'rgba(255, 77, 77, 0.1)'};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  animation: ${bounce} 2s infinite;
`;

const NoOperationsMessage = styled.p`
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  margin-top: 40px;
  animation: ${fadeInUp} 0.5s ease-out;
`;

const TraderOperationsList: React.FC<TraderOperationsListProps> = ({
  operations,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  
  const totalProfit = useMemo(() => operations.reduce((sum, op) => sum + op.profit, 0), [operations]);

  return (
    <HistoryContainer>
      <TotalProfitCard $isPositive={totalProfit >= 0}>
        <TotalProfitInner>
          <TotalProfitTitle>Total Profit</TotalProfitTitle>
          <TotalProfitAmount $isPositive={totalProfit >= 0}>
            <IconWrapper $isPositive={totalProfit >= 0}>
              {totalProfit >= 0 ? <HiTrendingUp /> : <HiTrendingDown />}
            </IconWrapper>
            {totalProfit >= 0 ? '+' : '-'}${formatProfit(Math.abs(totalProfit))}
          </TotalProfitAmount>
        </TotalProfitInner>
      </TotalProfitCard>

      {operations.length > 0 ? (
        operations.map((operation, index) => {
          const profit = operation.profit;

          return (
            <TransactionCard
              key={index}
              style={{ animationDelay: `${index * 0.1}s` }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <TransactionDetails>
                <TransactionTitle>Operation #{index + 1}</TransactionTitle>
                <TransactionSubtitle>
                  {new Date(operation.createdAt).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TransactionSubtitle>
              </TransactionDetails>
              <Amount $isPositive={profit >= 0}>
                <IconWrapper
                  $isPositive={profit >= 0}
                  style={{
                    transform: hoveredIndex === index ? 'scale(1.2)' : 'scale(1)',
                    transition: 'transform 0.3s ease',
                  }}
                >
                  {profit >= 0 ? <HiTrendingUp /> : <HiTrendingDown />}
                </IconWrapper>
                ${formatProfit(Math.abs(profit))}
              </Amount>
            </TransactionCard>
          );
        })
      ) : (
        <NoOperationsMessage>
          No operations found.
        </NoOperationsMessage>
      )}
    </HistoryContainer>
  );
};

export default TraderOperationsList;

