import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa";
import { IoChevronDown } from "react-icons/io5";

const LanguageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #1f1f1f, #292929);
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  &:hover {
    background: linear-gradient(90deg, #292929, #343434);
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 160px;
  background: #1f1f1f;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 9999;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

const DropdownItem = styled.div`
  padding: 10px 16px;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background 0.3s ease;

  &:hover {
    background: #2a2a2a;
  }
`;

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  const languages = [
    { code: "en", label: "English", flag: "🇺🇸" },
    { code: "pt", label: "Português", flag: "🇧🇷" },
    { code: "es", label: "Español", flag: "🇪🇸" },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LanguageContainer onClick={() => setIsOpen(!isOpen)}>
        <FaGlobe style={{ marginRight: "8px" }} />
        {languages.find((lang) => lang.code === i18n.language)?.flag}{" "}
        {languages.find((lang) => lang.code === i18n.language)?.label}
        <IoChevronDown style={{ marginLeft: "auto" }} />
      </LanguageContainer>

      <Dropdown className={isOpen ? "active" : ""}>
        {languages.map((lang) => (
          <DropdownItem key={lang.code} onClick={() => handleChangeLanguage(lang.code)}>
            {lang.flag} {lang.label}
          </DropdownItem>
        ))}
      </Dropdown>
    </div>
  );
};

export default LanguageSelector;
