import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  HeaderSection,
  Card,
  CardOverlay,
  Title,
  CarouselContainer,
  CarouselSlide,
  CarouselLines,
  Line,
  CopyCode,
  ButtonRow,
  IconButton,
  CardCopyCode,
} from "./ReferralPage.styles";
import HeaderLogged from "../../components/HeaderLogged";
import BottomMenu from "../../components/BottomMenu";
import LevelSection from "./LevelSection";
import {
  FaFacebook,
  FaPersonBooth,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import PainelControlSection from "./PainelControlSection";
import MinhasIndicacoesSection from "./MinhasIndicacoesSection";
import { UserContext } from "../../context/UserContext";
import { fetchUserHierarchy } from "../../services/userService";
import { MdDownload } from "react-icons/md";
import buildSite from "../../images/Setup-bro.png";
import { BiRegistered } from "react-icons/bi";
import { GiNetworkBars } from "react-icons/gi";
import { Menu, MenuItem } from "@mui/material";

const ReferralPage: React.FC = () => {
  const [userHierarchy, setUserHierarchy] = useState<any>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const { decodedToken } = useContext(UserContext);
  const referralCode = decodedToken.username;
  const referralLink = `${window.location.origin}/register?referrer=${referralCode}`;

  const slides = [
    {
      title: "",
      content:
        "💰 Increase your earnings! Refer friends, form your team and earn even more. 🚀",
    },
    // {
    //   title: "Seu Código de Indicação",
    //   content: `Use o código ${referralCode} para compartilhar com seus amigos.`,
    // },
  ];

  // Fetch user hierarchy
  useEffect(() => {
    const fetchHierarchy = async () => {
      try {
        const hierarchy = await fetchUserHierarchy(decodedToken?.id);
        setUserHierarchy(hierarchy);
      } catch (error) {
        console.error("Erro ao buscar hierarquia de usuários:", error);
      }
    };
    fetchHierarchy();
  }, []);

  // Carousel auto-slide logic
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const handleSlideChange = (index: number) => setCurrentSlide(index);

  return (
    <Container>
      <HeaderLogged nameScreen="Indications" />
      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <CarouselContainer>
              {slides.map((slide, index) => (
                <CarouselSlide
                  key={index}
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <Card>
                    <CardOverlay />
                    <Title>{slide.title}</Title>
                    <p>{slide.content}</p>
                  </Card>
                </CarouselSlide>
              ))}
            </CarouselContainer>

            <CarouselLines>
              {slides.map((_, index) => (
                <Line
                  key={index}
                  active={index === currentSlide}
                  onClick={() => handleSlideChange(index)}
                />
              ))}
            </CarouselLines>
          </div>
        </div>

        <div className="row justify-content-center mt-4">
          <div className="col-md-12 d-flex justify-content-center">
            <ReferralCodeSection
              referralCode={referralCode}
              referralLink={referralLink}
            />
          </div>
        </div>

        {/* Outras Seções */}
        <LevelSection />
        {/* <PainelControlSection /> */}
        {/* <MinhasIndicacoesSection userHierarchy={userHierarchy} /> */}
      
      </div>

      <BottomMenu />
    </Container>
  );
};

// Componente separado para exibir o código e link de indicação
const ReferralCodeSection: React.FC<{
  referralCode: string;
  referralLink: string;
}> = ({ referralCode, referralLink }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <CardCopyCode>
      {/* <Title>Seu Código de Indicação</Title>
    <CopyCode>
      <p>{referralCode}</p>
      <button onClick={() => navigator.clipboard.writeText(referralCode)}>Copiar</button>
    </CopyCode> */}
      <Title>Indication Link</Title>
      <CopyCode>
        <p>{referralLink}</p>
        <div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  backgroundColor: "#2a2a2a",
                  color: "#fff"
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                window.open("/BULL_PT-BR.pdf", "_blank");
                handleClose();
              }}
            >
              PT-BR Version
            </MenuItem>
            <MenuItem onClick={() => {
                window.open("/BULL_EN.pdf", "_blank");
                handleClose();
              }}>
              EN Version
            </MenuItem>
            <MenuItem onClick={() => {
                window.open("/BULL_ES.pdf", "_blank");
                handleClose();
              }}>
              ES Version
            </MenuItem>
          </Menu>
          <button onClick={handleClick}>
            Download Pdf <MdDownload />
          </button>
          <button onClick={() => navigator.clipboard.writeText(referralLink)}>
            Copy
          </button>
        </div>
      </CopyCode>
      <ButtonRow>
        <IconButton>
          <FaWhatsapp size={20} />
        </IconButton>
        <IconButton>
          <FaFacebook size={20} />
        </IconButton>
        <IconButton>
          <FaTwitter size={20} />
        </IconButton>
      </ButtonRow>
    </CardCopyCode>
  );
};

export default ReferralPage;
