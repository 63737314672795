import { CircularProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "outline" | "gradient"; // Variantes do botão
  size?: "small" | "medium" | "large"; // Tamanhos
  children: React.ReactNode; // Mudança aqui, agora espera `children`
  isLoading?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  width: 100%;
  padding: 10px;
  background: ${({ variant }) =>
    variant === "primary"
      ? "#400565"
      : variant === "secondary"
      ? "#ff6a00"
      : variant === "gradient"
      ? "linear-gradient(0deg, #800bcb 0%, rgb(128, 11, 203) 25%, rgb(64, 5, 101) 75%, #800bcb 100%);"
      : variant === "outline"
      ? "linear-gradient(180deg, rgba(34, 4, 54, 0.69) 0%, #220436 100%) !important"
      : "transparent"};
  border: none;
  ${({ variant }) =>
    variant === "outline"
      ? `
      border: 2px solid #6a00ff;
      backdrop-filter: blur(4px);
      box-shadow: 0px 4px 4px 0px #00000040;
       `
      : ""}
  border-radius: 5px;
  color: ${({ variant }) => (variant === "outline" ? "#fff" : "white")};
  font-size: 1em;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  transition: 200ms;
  background-size: 100% 400%;
  background-position: 100% 0%;
  &:hover {
    background: ${({ variant }) =>
      variant === "primary"
        ? "#6e0bab"
        : variant === "secondary"
        ? "#e65a00"
        : variant === "gradient"
        ? ""
        : variant === "outline"
        ? ""
        : "#f0f0f0"};
    opacity: ${({ variant }) => (variant === "outline" ? ".7" : "")};
    background-position: 100% 100%;
  }
`;

const Button: React.FC<ButtonProps> = ({
  onClick,
  disabled = false,
  variant = "primary",
  size = "medium",
  className,
  children,
  isLoading,
  ...rest
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={isLoading}
      variant={variant}
      size={size}
      className={className}
      {...rest}
    >
      {isLoading ? <CircularProgress /> : children}
    </StyledButton>
  );
};

export default Button;
