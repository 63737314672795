import React, { useEffect, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  MenuItem,
  Select,
  Box,
  Grid,
  TextField,
  TablePagination,
  Skeleton,
  IconButton,
  Collapse,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import api from "../../services/api"
import { formatUSD } from "../../utils/formatUSD"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import AdminLayout from "./components/AdminLayout"

const NUBANK_PURPLE = "#8A05BE"

interface User {
  name: string
  email: string
  phone?: string
  username?: string
}

interface WithdrawRequest {
  id: number
  amount: number
  pixKey: string
  status: "PENDING" | "PAID" | "CONFIRMED" | "INSUFFICIENT_FUNDS"
  createdAt: string
  user: User
}

interface ApiResponse<T> {
  count: number
  data: T[]
}

export default function WithdrawAdminPanel() {
  const [withdrawRequests, setWithdrawRequests] = useState<WithdrawRequest[]>([])
  const [loading, setLoading] = useState(true)
  const [emailQuery, setEmailQuery] = useState("")
  const [dateFrom, setDateFrom] = useState<Date | null>(null)
  const [dateTo, setDateTo] = useState<Date | null>(null)
  const [statusFilter, setStatusFilter] = useState<string>("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const [expandedRow, setExpandedRow] = useState<number | null>(null)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const identifyPixKeyType = (pixKey: string): "EMAIL" | "CPF" | "ALEATORIA" | "USDT" => {
    if (/^[a-zA-Z0-9]{34,42}$/.test(pixKey)) return "USDT"
    if (/^\d{11}$/.test(pixKey)) return "CPF"
    if (/^[\w-.]+@[\w-]+\.[a-z]+$/.test(pixKey)) return "EMAIL"
    return "ALEATORIA"
  }

  const calculateAmount = (amount: number, pixKey: string): { formattedValue: string; discountPercentage: string } => {
    const keyType = identifyPixKeyType(pixKey)
    const discountPercentage = keyType === "USDT" ? 5 : 8
    const finalAmount = amount * (1 - discountPercentage / 100)

    return {
      formattedValue: formatUSD(finalAmount),
      discountPercentage: `(${discountPercentage}%)`,
    }
  }

  const fetchWithdrawRequests = async () => {
    setLoading(true)
    try {
      const queryParams = new URLSearchParams({
        take: rowsPerPage.toString(),
        skip: (page * rowsPerPage).toString(),
      })

      if (emailQuery.trim()) queryParams.append("emailQuery", emailQuery.trim())
      if (dateFrom) queryParams.append("startDate", dateFrom.toISOString())
      if (dateTo) queryParams.append("endDate", dateTo.toISOString())
      if (statusFilter) queryParams.append("status", statusFilter)

      const response = await api.get<ApiResponse<WithdrawRequest>>(`/admin/withdrawRequests?${queryParams.toString()}`)
      setWithdrawRequests(response.data.data)
      setTotalRows(response.data.count)
    } catch (error) {
      console.error("Error fetching withdrawals:", error)
    } finally {
      setLoading(false)
    }
  }

  const clearDates = () => {
    setDateFrom(null)
    setDateTo(null)
  }

  useEffect(() => {
    fetchWithdrawRequests()
  }, [page, rowsPerPage, emailQuery, dateFrom, dateTo, statusFilter])

  const handleStatusChange = async (id: number, newStatus: WithdrawRequest["status"]) => {
    try {
      await api.patch(`/admin/withdrawRequest/updateStatus/${id}`, { status: newStatus })
      setWithdrawRequests((prev) =>
        prev.map((request) => (request.id === id ? { ...request, status: newStatus } : request)),
      )
    } catch (error) {
      console.error("Error updating status:", error)
    }
  }

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const toggleRow = (id: number) => {
    setExpandedRow(expandedRow === id ? null : id)
  }

  return (
    <AdminLayout title="Withdraw Requests">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Paper sx={{ p: 3, mb: 3, bgcolor: "#1a1a1a", borderRadius: "10px" }}>
          <Typography variant="h6" sx={{ mb: 2, color: "#fff" }}>
            Filters
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Search by Email"
                value={emailQuery}
                onChange={(e) => setEmailQuery(e.target.value)}
                sx={{
                  input: { color: "#fff" },
                  label: { color: "#fff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                    "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="From"
                value={dateFrom}
                onChange={(newValue) => setDateFrom(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: {
                      input: { color: "#fff" },
                      label: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                        "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="To"
                value={dateTo}
                onChange={(newValue) => setDateTo(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    sx: {
                      input: { color: "#fff" },
                      label: { color: "#fff" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                        "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                fullWidth
                label="Status"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                sx={{
                  color: "#fff",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.23)" },
                    "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                  },
                  "& .MuiSelect-icon": { color: "#fff" },
                  "& .MuiInputLabel-root": { color: "#fff" },
                }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="PENDING">PENDING</MenuItem>
                <MenuItem value="PAID">PAID</MenuItem>
                <MenuItem value="CONFIRMED">CONFIRMED</MenuItem>
                <MenuItem value="INSUFFICIENT_FUNDS">INSUFFICIENT_FUNDS</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
            <Button onClick={fetchWithdrawRequests} variant="contained">
              Confirm
            </Button>
            <Button onClick={clearDates} variant="outlined">
              Clear Dates
            </Button>
          </Box>
        </Paper>

        <Paper sx={{ bgcolor: "#1a1a1a", borderRadius: "10px" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>Expand</TableCell>
                  <TableCell sx={{ color: "#fff" }}>User Name</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Amount</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Transfer Key</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Requested At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Skeleton variant="rectangular" height={53} sx={{ bgcolor: "rgba(255, 255, 255, 0.1)" }} />
                    </TableCell>
                  </TableRow>
                ) : withdrawRequests.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ color: "#fff", textAlign: "center" }}>
                      No withdraw requests found.
                    </TableCell>
                  </TableRow>
                ) : (
                  withdrawRequests.map((row) => (
                    <React.Fragment key={row.id}>
                      <TableRow sx={{ "&:hover": { bgcolor: "rgba(138, 5, 190, 0.1)" } }}>
                        <TableCell>
                          <IconButton onClick={() => toggleRow(row.id)} sx={{ color: "#fff" }}>
                            {expandedRow === row.id ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>{row.user.name}</TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          {calculateAmount(row.amount, row.pixKey).formattedValue}{" "}
                          {calculateAmount(row.amount, row.pixKey).discountPercentage}
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>{row.pixKey}</TableCell>
                        <TableCell>
                          <Select
                            value={row.status}
                            onChange={(e) => handleStatusChange(row.id, e.target.value as WithdrawRequest["status"])}
                            sx={{
                              color: "#fff",
                              "& .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(255, 255, 255, 0.23)" },
                              "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(255, 255, 255, 0.5)" },
                              "& .MuiSvgIcon-root": { color: "#fff" },
                            }}
                          >
                            <MenuItem value="PENDING">PENDING</MenuItem>
                            <MenuItem value="PAID">PAID</MenuItem>
                            <MenuItem value="CONFIRMED">CONFIRMED</MenuItem>
                            <MenuItem value="INSUFFICIENT_FUNDS">INSUFFICIENT_FUNDS</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell sx={{ color: "#fff" }}>
                          {format(new Date(row.createdAt), "dd/MM/yyyy HH:mm")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6} sx={{ py: 0, bgcolor: "#222" }}>
                          <Collapse in={expandedRow === row.id} timeout="auto" unmountOnExit>
                            <Box sx={{ py: 2 }}>
                              <Typography variant="body2" sx={{ color: "#fff" }}>
                                📧 Email: {row.user.email}
                              </Typography>
                              <Typography variant="body2" sx={{ color: "#fff" }}>
                                📱 Phone: {row.user.phone}
                              </Typography>
                              <Typography variant="body2" sx={{ color: "#fff" }}>
                                👤 Username: {row.user.username}
                              </Typography>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            sx={{
              color: "#fff",
              "& .MuiTablePagination-select": { color: "#fff" },
              "& .MuiTablePagination-selectIcon": { color: "#fff" },
              "& .MuiTablePagination-displayedRows": { color: "#fff" },
              "& .MuiIconButton-root": {
                color: "#fff",
                "&.Mui-disabled": { color: "rgba(255, 255, 255, 0.3)" },
              },
            }}
          />
        </Paper>
      </LocalizationProvider>
    </AdminLayout>
  )
}

