import React, { useEffect, useState, useContext } from "react";
import styled, { keyframes } from "styled-components";
import {
  RiMoneyDollarCircleLine,
  RiBarChartBoxLine,
  RiTeamLine,
  RiQuestionLine,
  RiCustomerService2Line,
  RiWalletLine
} from "react-icons/ri";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { getWeeklyRange, prepareChartData } from "../../utils/utils";
import { formatUSD } from "../../utils/formatUSD";
import api from "../../services/api";
import StatementCard from "../../components/StatementCard";
import DashboardChart from "./DashboardChart";
import ReferralCard from "../../components/ReferralCard";
import RedeemBonus from "../../components/RedeemBonusContainer ";
import HeaderLogged from "../../components/HeaderLogged";
import BottomMenu from "../../components/BottomMenu";

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

const DashboardContainer = styled.div`
  background-color: #000000;
  color: #FFFFFF;
  padding: 60px 0;
  min-height: 100vh;
  margin-bottom: 50px;
`;

const SectionTitle = styled.h2`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #FFFFFF;
  text-align: left;
  letter-spacing: 2px;
`;

const LevelCard = styled.div`
  background-color: #1A1A1A;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #8A2BE2;

  p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 1rem;
    color: #FFFFFF;
  }

  b {
    font-size: 1.5rem;
    color: #8A2BE2;
    font-weight: 700;
  }
`;

const SkeletonBox = styled.div`
  background: linear-gradient(90deg, #1A1A1A 25%, #2A2A2A 50%, #1A1A1A 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  border-radius: 5px;
`;

const LevelSkeleton = styled(SkeletonBox)`
  width: 100%;
  height: 70px;
`;

const ChartSkeleton = styled(SkeletonBox)`
  width: 100%;
  height: 300px;
  border-radius: 10px;
`;

const TableSkeleton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TableRowSkeleton = styled(SkeletonBox)`
  height: 40px;
  width: 100%;
`;

const DepositButton = styled.div`
  background-color: #8A2BE2;
  color: #FFFFFF;
  padding: 15px 30px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #9B30FF;
  }
`;

const HelpContainer = styled.div`
  background-color: #1A1A1A;
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
  border: 1px solid #8A2BE2;
`;

const HelpText = styled.p`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1.1rem;
  color: #FFFFFF;
  margin: 0;

  a {
    color: #8A2BE2;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;

    &:hover {
      color: #9B30FF;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: 15px;
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  color: #8A2BE2;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const TableRow = styled.tr`
  background-color: #1A1A1A;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2A2A2A;
  }
`;

const TableCell = styled.td`
  padding: 15px;
  border: 1px solid #333333;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
`;

const DashboardHomeSection: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<any | null>(null);
  const [chartData, setChartData] = useState<{ categories: string[]; series: number[] }>({
    categories: [],
    series: [],
  });
  const { decodedToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        if (!decodedToken?.id) return;

        const { startOfWeek, endOfWeek } = getWeeklyRange(new Date());
        const formattedStartDate = format(startOfWeek, "yyyy-MM-dd");
        const formattedEndDate = format(endOfWeek, "yyyy-MM-dd");

        const [dashboardResponse, chartResponse] = await Promise.all([
          api.get(`/user/dashboard/home/${decodedToken.id}`),
          api.get(`/user/dashboard/combined/${decodedToken.id}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`),
        ]);

        setDashboardData(dashboardResponse.data.data);

        const rawChartData = chartResponse.data.data.chart;
        const { categories, series } = prepareChartData(rawChartData, startOfWeek, endOfWeek);
        setChartData({ categories, series });
      } catch (error) {
        console.error("Error fetching dashboard or chart data:", error);
        setChartData({ categories: [], series: [] });
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboard();
  }, [decodedToken]);

  const renderEarningsDetails = () => {
    if (isLoading) {
      return (
        <TableSkeleton>
          {[1, 2, 3].map((i) => (
            <TableRowSkeleton key={i} />
          ))}
        </TableSkeleton>
      );
    }

    if (!dashboardData?.traderDetails?.length) {
      return (
        <div style={{ padding: "20px", textAlign: "center", color: "#8A2BE2" }}>
          No trading data available
        </div>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <TableHeader>Trader</TableHeader>
            <TableHeader style={{ textAlign: 'right' }}>Amount</TableHeader>
            {/* <TableHeader style={{ textAlign: 'right' }}>Profit</TableHeader> */}
          </tr>
        </thead>
        <tbody>
          {dashboardData.traderDetails.map((contract: any) => (
            <TableRow key={contract.name}>
              <TableCell>{contract.name.split(" ")[0]}</TableCell>
              <TableCell style={{ textAlign: 'right' }}>{formatUSD(contract.amount)}</TableCell>
              {/* <TableCell style={{ textAlign: 'right' }}>{formatUSD(contract.profit)}</TableCell> */}
            </TableRow>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <HeaderLogged />
      <DashboardContainer>
        <div className="container">
          <SectionTitle>Financial Overview</SectionTitle>

          <DashboardGrid>
            {isLoading ? (
              <LevelSkeleton />
            ) : (
              <LevelCard>
                <p>
                  Qualification <RiQuestionLine />
                </p>
                <b>Level {dashboardData?.userQualification || 0}</b>
              </LevelCard>
            )}
            <StatementCard
              title="Withdrawals"
              value={dashboardData?.withdraws || 0}
              icon={<RiWalletLine />}
              isLoading={isLoading}
            />
            <StatementCard
              title="Investments"
              value={dashboardData?.earnings || 0}
              icon={<RiBarChartBoxLine />}
              isLoading={isLoading}
              collapseContent={renderEarningsDetails()}
            />
            <StatementCard
              title="Referrals"
              value={dashboardData?.referrals || 0}
              icon={<RiTeamLine />}
              isLoading={isLoading}
            />
          </DashboardGrid>

          <div className="row mt-4">
            <div className="col-md-8 col-12 mb-4">
              {isLoading ? (
                <ChartSkeleton />
              ) : (
                <DashboardChart data={chartData} />
              )}
            </div>
            <div className="col-md-4 col-12 mb-4">
              <ReferralCard
                isLoading={isLoading}
                totalReferrals={dashboardData?.totalReferrals || 0}
                referrals={[
                  { level: "Level 1", count: dashboardData?.childrenPerLevel?.childrenLevel1 || 0 },
                  { level: "Level 2", count: dashboardData?.childrenPerLevel?.childrenLevel2 || 0 },
                  { level: "Level 3", count: dashboardData?.childrenPerLevel?.childrenLevel3 || 0 },
                  { level: "Level 4", count: dashboardData?.childrenPerLevel?.childrenLevel4 || 0 },
                  { level: "Level 5", count: dashboardData?.childrenPerLevel?.childrenLevel5 || 0 },
                ]}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center">
              <DepositButton onClick={() => navigate("/deposit")}>
                <RiMoneyDollarCircleLine /> Deposit Now
              </DepositButton>
            </div>
          </div>

          <HelpContainer>
            <HelpText>
              <RiCustomerService2Line style={{ fontSize: "1.8rem", color: "#8A2BE2" }} />
              Need assistance? <a href="http://t.me/bullfxsup" target="_blank" rel="noopener noreferrer">Contact our support team</a>
            </HelpText>
          </HelpContainer>
        </div>
      </DashboardContainer>
      <BottomMenu />
    </>
  );
};

export default DashboardHomeSection;
