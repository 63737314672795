import type React from "react"
import { Paper, Typography, Box, Skeleton } from "@mui/material"

interface StatCardProps {
  title: string
  value: string
  color: string
  backgroundColor?: string
}

const StatCard: React.FC<StatCardProps> = ({ title, value, color, backgroundColor = "#121212" }) => {
  return (
    <Paper
      sx={{
        p: { xs: 2, sm: 3 },
        display: "flex",
        flexDirection: "column",
        height: { xs: 120, sm: 140 },
        background: backgroundColor,
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: color,
          fontSize: { xs: "1rem", sm: "1.25rem" },
        }}
      >
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}>
        <Typography
          variant="h4"
          component="p"
          sx={{
            color: color,
            fontWeight: "bold",
            fontSize: { xs: "1.5rem", sm: "2.125rem" },
          }}
        >
          {value}
        </Typography>
      </Box>
    </Paper>
  )
}

export default StatCard

