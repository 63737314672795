import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { 
  AiOutlineHome, 
  AiOutlineUser, 
  AiOutlineTeam,
  AiOutlineMore,
  AiOutlineHistory,
  AiOutlineWallet,
  AiOutlineBank,
  AiOutlineProfile
} from "react-icons/ai";
import { FaTelegram } from "react-icons/fa";

const expandAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MenuContainer = styled.nav<{ $isExpanded: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(26, 26, 26, 0.85);
  backdrop-filter: blur(10px);
  border-top: 2px solid #8A2BE2;
  z-index: 1000;
  transition: height 0.3s ease;
  height: ${props => props.$isExpanded ? '120px' : '60px'};
  overflow: hidden;
`;

const MenuGrid = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
`;

const MenuItem = styled.button<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.$isActive ? '#8A2BE2' : '#FFFFFF'};
  background: none;
  border: none;
  padding: 5px;
  transition: all 0.3s ease;
  font-size: 0.7rem;
  width: 60px;
  height: 60px;

  &:hover {
    color: #8A2BE2;
  }

  svg {
    font-size: 1.5rem;
    margin-bottom: 3px;
  }
`;

const MenuText = styled.span`
  font-size: 0.6rem;
`;

const ExpandButton = styled(MenuItem)``;

const ExpandedMenu = styled.div<{ $isExpanded: boolean }>`
  display: ${props => (props.$isExpanded ? 'grid' : 'none')};
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Ajusta dinamicamente o número de colunas */
  gap: 10px; /* Aumenta o espaçamento entre itens */
  padding: 10px 15px; /* Adiciona mais espaço ao redor */
  background-color: rgba(26, 26, 26, 0.95);
  animation: ${expandAnimation} 0.3s ease forwards;
`;


const ExpandedMenuItem = styled(MenuItem)`
  height: 45px;
  width: 100%;
  font-size: 0.65rem;

  svg {
    font-size: 1.2rem;
    margin-bottom: 2px;
  }
`;


const mainMenuItems = [
  { path: "/home", icon: <AiOutlineHome />, label: "Home" },
  { path: "/dashboard/user", icon: <AiOutlineUser />, label: "Dashboard" },
  { path: "/dashboard", icon: <AiOutlineTeam />, label: "Referreals" },
  { path: "http://t.me/bullfxsup", icon: <FaTelegram />, label: "Suport", external: true },
];

const expandedMenuItems = [
  { path: "/deposit", icon: <AiOutlineBank />, label: "Deposit" },
  { path: "/withdrawal", icon: <AiOutlineWallet />, label: "Withdraw" },
  { path: "/my-deposits", icon: <AiOutlineHistory />, label: "My Deposits" },
  // { path: "/statements", icon: <AiOutlineProfile />, label: "Statements" },
  { path: "/profile", icon: <AiOutlineUser />, label: "Profile" },
];

const BottomMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleNavigation = (path: string, external?: boolean) => {
    if (external) {
      window.open(path, "_blank", "noopener,noreferrer");
    } else {
      navigate(path);
      setIsExpanded(false);
    }
  };

  return (
    <MenuContainer $isExpanded={isExpanded}>
      <MenuGrid>
        {mainMenuItems.map((item) => (
          <MenuItem
            key={item.path}
            onClick={() => handleNavigation(item.path, item.external)}
            $isActive={location.pathname === item.path}
          >
            {item.icon}
            <MenuText>{item.label}</MenuText>
          </MenuItem>
        ))}
        <ExpandButton 
          onClick={() => setIsExpanded(!isExpanded)}
          $isActive={isExpanded}
        >
          <AiOutlineMore />
          <MenuText>{isExpanded ? 'Less' : 'More'}</MenuText>
        </ExpandButton>
      </MenuGrid>
      <ExpandedMenu $isExpanded={isExpanded}>
        {expandedMenuItems.map((item) => (
          <ExpandedMenuItem
            key={item.path}
            onClick={() => handleNavigation(item.path)}
            $isActive={location.pathname === item.path}
          >
            {item.icon}
            <MenuText>{item.label}</MenuText>
          </ExpandedMenuItem>
        ))}
      </ExpandedMenu>
    </MenuContainer>
  );
};

export default BottomMenu;

