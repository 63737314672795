import React from "react";
import styled, { keyframes } from "styled-components";
import { formatProfit, formatUSD } from "../utils/formatUSD";
import { formatDate } from "../utils/utils";

interface InvestmentCardProps {
  name: string;
  bio?: string;
  profileImageSrc?: string;
  previousProfit: string | number;
  estimatedProfit: string | number;
  operationTerm: string | number;
  minimumInvestment: number;
  maximumInvestment: number;
  amount: number;
  startDate: string;
  endDate: string;
  profit: number;
  traderId: number;
  dailyProfit: number;
  term: any;
  accumulatedProfit: number;
  onClick: () => void;
}

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const Card = styled.div`
  width: 100%;
  max-width: 600px;
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  color: white;
  margin: 20px auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.6);
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 255, 149, 0.5);
  }

  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`;

const NameAndInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const InfoText = styled.div`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3px;

  @media (max-width: 500px) {
    font-size: 0.7rem;
  }
`;

const HistoryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #00ff95, #12ca7d);
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  color: #400565;
  font-weight: bold;
  font-size: 0.7rem;
  cursor: pointer;
  gap: 8px;
  box-shadow: 0px 4px 10px rgba(0, 255, 149, 0.3);
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 15px rgba(0, 255, 149, 0.5);
  }

  @media (max-width: 500px) {
    padding: 10px 20px;
  }
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatLabel = styled.div`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin-bottom: 5px;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

const StatValue = styled.div`
  display: flex;
  gap: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const Plus = styled.span`
  color: #ffffff;
`;

const Separator = styled.div`
  width: 1px;
  background: linear-gradient(180deg, rgba(162, 191, 254, 0.5), #800bcb);
  margin: 0 15px;
`;

const ProgressBarContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ $progress: number }>`
  height: 10px;
  background: linear-gradient(90deg, #00ff95, #12ca7d);
  width: ${props => props.$progress}%;
  transition: width 0.5s ease-out;
`;

const InvestmentCard: React.FC<InvestmentCardProps> = ({
  name,
  bio,
  profileImageSrc,
  previousProfit,
  estimatedProfit,
  operationTerm,
  minimumInvestment,
  maximumInvestment,
  amount,
  startDate,
  endDate,
  profit,
  traderId,
  dailyProfit,
  accumulatedProfit,
  term,
  onClick,
}) => {
  const normalizeDate = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const start = normalizeDate(new Date(startDate));
  const end = normalizeDate(new Date(endDate));
  const now = normalizeDate(new Date());

  // Clampa a data atual para não ultrapassar o final
  const clampedNow = now > end ? end : now;

  // Calcula os dias totais e os dias transcorridos
  const totalDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  const elapsedDays = Math.max(
    Math.floor((clampedNow.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)),
    0
  );

  // Calcula a porcentagem do progresso
  const progress = totalDays > 0 ? (elapsedDays / totalDays) * 100 : 0;


  console.log({
    start: start.toString(),
    end: end.toString(),
    now: now.toString(),
    elapsedDays,
    totalDays,
    progress,
  });

  const accumulatedProfitPercent = formatProfit(accumulatedProfit);
  const estimatedProfitValue = parseFloat(estimatedProfit.toString());
  const dailyProfitValue = (estimatedProfitValue / term).toFixed(2);

  return (
    <Card>
      <Header>
        <ProfileInfo>
          <Avatar src={profileImageSrc} alt={`${name}'s avatar`} />
          <NameAndInfo>
            <Name>{name}</Name>
            {bio && <InfoText>{bio}</InfoText>}
          </NameAndInfo>
        </ProfileInfo>
        <HistoryButton onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}>
          History
        </HistoryButton>
      </Header>

      <Stats>
        <Stat>
          <StatLabel>Capital</StatLabel>
          <StatValue>{formatUSD(amount)}</StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>Start Date</StatLabel>
          <StatValue>{formatDate(startDate)}</StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>End Date</StatLabel>
          <StatValue>{formatDate(endDate)}</StatValue>
        </Stat>
      </Stats>

      <Stats>
        <Stat>
          <StatLabel>Daily Profit</StatLabel>
          <StatValue>
            <Plus>+</Plus>{dailyProfitValue}%
          </StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>Accumulated Profit</StatLabel>
          <StatValue>
            <Plus>+</Plus>${accumulatedProfitPercent}
          </StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>Estimated Profit</StatLabel>
          <StatValue>
            <Plus>+</Plus>{estimatedProfit}%
          </StatValue>
        </Stat>
      </Stats>

      <ProgressBarContainer>
        <ProgressBar $progress={progress} />
      </ProgressBarContainer>
    </Card>
  );
};

export default InvestmentCard;

