import React, { useContext } from "react";
import styled from "styled-components";
import UserProfile from "./organisms/UserProfile";
import LanguageSelector from "./LanguageSelector"; 
import { UserContext } from "../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../images/logo.webp";

interface IHeaderLogged {
  nameScreen?: string | React.ReactNode;
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 8px;
  background: linear-gradient(180deg, #800bcb 0%, #400565 80.46%);
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .logo {
    width: 90px;
    cursor: pointer;
  }

  .row-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const HeaderLogged: React.FC<IHeaderLogged> = ({ nameScreen }) => {
  const { decodedToken, formattedBalance } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <>
      <HeaderContainer>
        <img
          className="logo"
          onClick={() => navigate("/home")}
          src={Logo}
          alt="Logo"
        />
        <div className="row-container">

          <UserProfile name={decodedToken?.username} balance={formattedBalance} />
        </div>

      </HeaderContainer>
      <div className="container">
        <div className="row pt-3">
          <div className="col-md-12">
            <LanguageSelector />
          </div>
        </div>
      </div>
    </>

  );
};

export default HeaderLogged;
