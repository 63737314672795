import React, { useContext, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdGroups } from "react-icons/md";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import FormField from "./molecules/FormField";
import Button from "./atoms/Button";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";
import { centsUSDToCentsBRL, formatProfit, formatUSD } from "../utils/formatUSD";
import { useTranslation } from "react-i18next";

interface InfluencerCardProps {
  name: string;
  subscribers: number;
  previousProfit: string | number;
  operationTerm: string | number;
  estimatedProfit: string | number;
  profileImageSrc?: string;
  value?: any;
  minimumInvestment: number;
  maximumInvestment: number;
  term: number;
  id: number;
  country?: string;
  market?: string;
  bio?: string;
}

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const Card = styled.div`
  width: 100%;
  max-width: 600px;
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  color: white;
  margin: 20px auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.6);
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 255, 149, 0.5);
  }

  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`;

const NameAndInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 5px;

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const InfoText = styled.div`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3px;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

const SubscribeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #00ff95, #12ca7d);
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  color: #400565;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;
  gap: 8px;
  box-shadow: 0px 4px 10px rgba(0, 255, 149, 0.3);
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 15px rgba(0, 255, 149, 0.5);
  }

  @media (max-width: 500px) {
    padding: 10px 20px;
    font-size: 1rem;
  }
`;

const MoneyValue = styled.div`
  color: #00ff95;
  font-size: 1.6rem;
  font-weight: bold;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatLabel = styled.div`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin-bottom: 5px;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

const StatValue = styled.div`
  display: flex;
  gap: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const Plus = styled.span`
  color: #ffffff;
`;

const Separator = styled.div`
  width: 1px;
  background: linear-gradient(180deg, rgba(162, 191, 254, 0.5), #800bcb);
  margin: 0 15px;
`;

const TextModal = styled.p`
  font-size: 1rem;
  color: #fff;
  opacity: 0.9;
  margin-top: 15px;
  b {
    color: #00ff95;
  }
`;

const SpacedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 12px;
  padding-bottom: 8px;
`;

const TraderDialogCard = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(64, 5, 101, 0.3);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
`;

const InvestmentActions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;

  button {
    background: none;
    border: solid #ffffff 1px;
    color: #ffffff;
    border-radius: 6px;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: #00ff95;
      color: #400565;
    }
  }
`;

const InfluencerCard: React.FC<InfluencerCardProps> = ({
  name,
  subscribers,
  previousProfit,
  operationTerm,
  estimatedProfit,
  profileImageSrc,
  value,
  maximumInvestment,
  minimumInvestment,
  term,
  id,
  country,
  market,
  bio
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [investment, setInvestment] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { formattedBalance, decodedToken, refreshUserInfo } = useContext(UserContext);

  const handleChange = (e: string) => {
    const numericValue = e.replace(/[^0-9\.]+/g, "");
    setInvestment(numericValue);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!investment) {
        return Swal.fire("Error", "Add an investment!", "error");
      }

      const endDate = new Date();
      endDate.setDate(endDate.getDate() + term);

      await api.post("traders/transfer", {
        userId: decodedToken.id,
        traderId: id,
        amount: centsUSDToCentsBRL(parseInt(investment) * 100),
        startDate: new Date().toISOString(),
        endDate,
      });
      refreshUserInfo();
      setOpenModal(false);
      Swal.fire({
        title: "Success",
        text: "Investment successful!",
        icon: "success",
        background: "#000",
        color: "#fff",
        confirmButtonColor: "#00ff95",
        iconColor: "#00ff95",
      });
      window.location.reload();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Oops! Something went wrong.",
        icon: "error",
        background: "#000",
        color: "#fff",
        confirmButtonColor: "#ff4d4f",
        iconColor: "#ff4d4f",
      });
    } finally {
      setLoading(false);
    }
  };
  const { t }: { t: any } = useTranslation(); 
  return (
    <>
    <Card>
      <Header>
        <ProfileInfo>
          <Avatar src={profileImageSrc} alt={`${name}'s avatar`} />
          <NameAndInfo>
            <Name>{name}</Name>
            <InfoText>{t("trader.country")}: {country}</InfoText>
            <InfoText>{t("trader.market")}: {market}</InfoText>
          </NameAndInfo>
        </ProfileInfo>
        {value === undefined ? (
          <SubscribeButton onClick={() => setOpenModal(true)}>
            {t("trader.invest")} <AiOutlineUserAdd />
          </SubscribeButton>
        ) : (
          <MoneyValue>{formatProfit(value)}</MoneyValue>
        )}
      </Header>
      <Stats>
        <Stat>
          <StatLabel>{t("trader.previous_profit")}</StatLabel>
          <StatValue>
            <Plus>+</Plus> {previousProfit}
          </StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>{t("trader.operation_term")}</StatLabel>
          <StatValue>{operationTerm}</StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>{t("trader.estimated_profit")}</StatLabel>
          <StatValue>
            <Plus>+</Plus> {estimatedProfit}%
          </StatValue>
        </Stat>
      </Stats>
      <Stats>
        <Stat>
          <StatLabel>{t("trader.duration")}</StatLabel>
          <StatValue>{term} {t("trader.days")}</StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>{t("trader.min_investment")}</StatLabel>
          <StatValue>{formatUSD(Number(minimumInvestment))}</StatValue>
        </Stat>
        <Separator />
        <Stat>
          <StatLabel>{t("trader.max_investment")}</StatLabel>
          <StatValue>{formatUSD(Number(maximumInvestment))}</StatValue>
        </Stat>
      </Stats>
    </Card>
    <Dialog
      open={openModal}
      fullWidth
      maxWidth="md"
      onClose={() => setOpenModal(false)}
      aria-labelledby="investment-dialog-title"
      aria-describedby="investment-dialog-description"
      sx={{
        zIndex: 10,
        ".MuiPaper-root": {
          backgroundColor: "#1c1c1c",
          color: "#f5f5f5",
          borderRadius: "16px",
          padding: "24px",
        },
      }}
    >
      <DialogTitle
        id="investment-dialog-title"
        sx={{
          fontSize: "1rem",
          fontWeight: "bold",
          borderBottom: "1px solid #444",
          paddingBottom: "15px",
          color: "#ffffff",
        }}
      >
        {t("trader.investment_opportunity")}
        <IconButton
          aria-label="close"
          onClick={() => setOpenModal(false)}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: "#f5f5f5",
          }}
        >
          <IoClose />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: "24px" }}>
        <TraderDialogCard>
          <ProfileInfo>
            <Avatar src={profileImageSrc} alt={`${name}'s avatar`} />
            <NameAndInfo>
              <Name>{name}</Name>
              <InfoText>{bio}</InfoText>
            </NameAndInfo>
          </ProfileInfo>
        </TraderDialogCard>
  
        <TextModal>{t("trader.available_balance")}: <b>{formattedBalance}</b> USD</TextModal>
  
        <div>
          <TextModal>{t("trader.investment_amount")}</TextModal>
          <NumericFormat
            value={investment}
            prefix={"$"}
            allowNegative={false}
            customInput={FormField}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(e) => {
              handleChange(e.value);
            }}
            placeholder={`${t("trader.min_investment_placeholder")} ${formatUSD(minimumInvestment)} USDT`}
            style={{ border: "2px solid #ffffff", borderRadius: "8px", padding: "10px", fontSize: "1.1rem" }}
          />
          <InvestmentActions>
            <button onClick={() => setInvestment(minimumInvestment / 100)}>
              {t("trader.min")}
            </button>
            <button onClick={() => setInvestment(maximumInvestment / 100)}>
              {t("trader.max")}
            </button>
          </InvestmentActions>
  
          <SpacedDiv>
            <TextModal>{t("trader.min_investment")}</TextModal>
            <TextModal>{formatUSD(minimumInvestment)} USDT</TextModal>
          </SpacedDiv>
          <SpacedDiv>
            <TextModal>{t("trader.max_investment")}</TextModal>
            <TextModal>{formatUSD(maximumInvestment)} USDT</TextModal>
          </SpacedDiv>
          <Button isLoading={loading} variant="gradient" size="large" onClick={handleSubmit} style={{
            marginTop: '20px',
            width: '100%',
            padding: '12px',
            fontSize: '1.2rem',
            background: 'linear-gradient(90deg, #00ff95, #12ca7d)',
            color: '#400565',
            border: 'none',
            borderRadius: '25px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
          }}>
            {t("trader.invest_now")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  </>
  
  );
};

export default InfluencerCard;

