// AdminRoute.tsx
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import api from './services/api';
import { UserContext } from './context/UserContext';

const AdminRoute: React.FC = () => {
  const {decodedToken} =useContext(UserContext)

  return decodedToken.isAdmin ? <Outlet /> : <Navigate to="/home"/>;
};

export default AdminRoute;